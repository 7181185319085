.feines-grid {
    margin-top: -100px;
*,*:before,*:after {box-sizing:inherit;}

.hide {display: none;}
@media only screen and (min-width: 800px) {
.wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(calc(8rem + 5vw + 5vh),1fr));
    grid-auto-flow: dense;
    grid-gap: 2.5vmin;
    padding: 2.5vmin;
}
figure {
    margin: 0;
    position: relative;
}
figure:first-child {
    grid-row-start: span 3;
    grid-column-start: span 3;
}
figure:nth-child(2n+3) {
    grid-row-start: span 3;
}
figure:nth-child(4n+5) {
    grid-column-start: span 2;
        grid-row-start: span 2;
}
figure:nth-child(6n+7) {
    grid-row-start: span 2;
}
figure:nth-child(8n+9) {
    grid-column-start: span 2;
    grid-row-start: span 3;
}
img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
}

img {
  animation: show 3s forwards;
}
@keyframes show {
  0% {filter: blur(50px)}
  100% {filter: blur(0px)}
}

figcaption {
    /*background: rgba(103, 103, 103, 0.7);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;*/
}
a {color: #f7e371}

}
.galery-feines {
    padding-top: 0;
    p {
        margin-bottom: 0 !important;
    }
    figure {
        position: relative;
        /*width: 90%;
        max-width: 400px;
        margin: auto;
        overflow: hidden;*/
        margin: 0;
        margin-bottom: rem-calc(15);
       figcaption {
        //display: none;
            h3 {
                color: #fff;
                margin-bottom: 0;
                font-size: rem-calc(20);
            }
        }
        .content-overlay {
              background: rgba(0, 0, 0,0.8);
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              opacity: 0;
              -webkit-transition: all 0.4s ease-in-out 0s;
              -moz-transition: all 0.4s ease-in-out 0s;
              transition: all 0.4s ease-in-out 0s;
              z-index: 1;
        }
        .content-image{
          width: 100%;
        }

        .content-details {
          position: absolute;
          text-align: center;
          padding-left: 1em;
          padding-right: 1em;
          width: 100%;
          top: 50%;
          left: 50%;
          opacity: 0;
          z-index: 2;
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.15em;
          text-transform: uppercase;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          -webkit-transition: all 0.3s ease-in-out 0s;
          -moz-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s;
          .content-title {
            font-size: rem-calc(20);
            letter-spacing: 0;
            text-transform: none;
            @include demibold;
          }
          .content-text {
            font-size: rem-calc(16);
            letter-spacing: 0;
            text-transform: none;
            @include light;
          }
        }
        &:hover {
            figcaption {
                //display: block;
            }
            .content-overlay {
                    opacity: 1;
            }
            .content-details{
                  top: 50%;
                  left: 50%;
                  opacity: 1;
                }
            }

        .fadeIn-bottom{
            top: 80%;
        }
    }
    .columns+.columns:last-child {
        float: left !important;
    }
}
.feines-fitxa {
    .close {
        position: absolute;
        right: rem-calc(15);
        top: rem-calc(300);
    }
    .feines-content {
        img {
            padding-bottom: rem-calc(20);
        }
        p, ul {
            padding-bottom: rem-calc(15);
        }

    }
}
