.table {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2rem 0rem 0rem 0rem;
  
    .table-cell {
      box-sizing: border-box;
      flex-grow: 1;
      width: 100%;
      padding: 0.8em 1.2em;
      overflow: hidden;
      list-style-type: none;
      outline: 1px solid #ddd;
      text-align: center;
      font-weight: 300;
      .info-box & {
        background-color: #f9f9f9;
      }
      margin: {
        top: 1px;
        left: 1px;
      }
      &:first-child {
        outline: 1px solid #fff;
        background: transparent;
        @media only screen and (max-width : 768px) {
                display: none;
            }
      }
      &:nth-child(1) {
        outline: 1px solid transparent;
      }
      &:nth-child(-n+3) {
        padding: {
            top: 40px;
            bottom: 40px;
        }
      }
      > h3 {
        font-size: rem-calc(23);
        color: #fff;
        margin-top: 0;
      }
      &.cell-feature {
          text-align: left;
        font-size: 18px;
      }
      &.progessive {
        color: #fff;
      }
      &.native {
        color: #fff;
      }
    }

    /* Table columns
    ================================== */
    .table-cell  {
      width: calc(33.33% - 1px);
      &.cell-feature {
          @media only screen and (max-width : 768px) {
              width: 100%;
              text-align: center;
          }
      }
    }

    svg.native-check path {
      fill: #30305b !important;
    }


}
