.floor {
    text-align: center;
    padding: rem-calc(90 0);

    // fitxa
    &--view {
        padding-top: 0;
        p {
            font-size: rem-calc(14);
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            @media #{$medium-up} {
                font-size: rem-calc(17);
                width: 65%;
            }
        }
    }

    // imatge de fons
    &--pict {
        background-size: cover;
        background-position: 50% 0;
        background-size: cover;
    }

    // introducció
    &--intro {
        padding-top: 0;
        background-color: #dedede;
        background-image: linear-gradient(to bottom, #dedede, $white);
        hr {
            border: solid #000;
            border-width: 1px 0 0;
            width: 95px;
            margin: 0 auto;
            padding: 15px 0;
        }
        &__txt {
            font-size: rem-calc(14);
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            @media #{$medium-up} {
                font-size: rem-calc(17);
                width: 65%;
            }

            .cms & {
                @media #{$medium-up} {
                    // width: 80%;
                }
            }
        }
        p {
            font-size: rem-calc(14);
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            @media #{$medium-up} {
                font-size: rem-calc(17);
                width: 65%;
            }

            .cms & {
                @media #{$medium-up} {
                    // width: 80%;
                }
            }
        }

        .home & {
            padding-top: rem-calc(160);
            background-color: black;
            background-image: none;

            .no-js & {
                padding-top: rem-calc(100);
                @media #{$medium-up} {
                    padding-top: rem-calc(200);
                }
            }

            @media #{$medium-up} {
                background-attachment: fixed;
                padding-top: rem-calc(210);
                min-height: 100vh;
                background-image: url("img/home-img-2.jpg");
                background-size: cover;
                @media #{$retina} {
                    background-image: url("img/home-img-2@2x.jpg");
                }
            }

            @media #{$xlarge-up} {
                // padding-top: rem-calc(250);
            }
        }

        .sub-home & {
            padding-bottom: 0;
        }

        .down-link {
            display: none;
            @media #{$large-up} {
                display: block;
                position: absolute;
                bottom: 5%;
                right: 0;
                left: 0;
            }
        }
    }

    // grid portada
    &--work {
        padding: 0;
        overflow: hidden; // per evitar scroll horitzontal
        background-color: black;
        background-image: linear-gradient(to bottom, #352c21, #262626);

        @media #{$small-only} {
            .column {
                @include grid-column($collapse: true);
            }
        }
        > .row {
            max-width: 79rem; /*ampliació de l'amplada del row a portada*/
        }
    }
    .sep {
        @media #{$medium-up} {
            border-left: 1px solid #979797;
            padding-left: rem-calc(70);
        }
    }
    // call to action
    &--form {
        background-color: #24bdf2;
        border-top: 5px solid #fff;
        color: #fff;
        text-align: left;
        padding-top: rem-calc(30);
        @media #{$medium-up} {
            padding-top: rem-calc(60);
        }
        a {
            color: #fff;
        }
        .botons {
            text-decoration: none;
            margin-left: 0 !important;
            margin-top: rem-calc(30);
            li {
                display: inline-block;
                margin-right: rem-calc(15);
                margin-bottom: 30px;
                a {
                    padding: rem-calc(10 20);
                    text-decoration: none;
                    border-radius: 30px;
                    border: 2px solid #fff;
                    &:hover {
                        background-color: #fff;
                        color: #24bdf2;
                    }
                }
            }
        }
    }
    &--cta {
        .home & {
            background-color: #4a4a4a;
            @media #{$medium-up} {
                padding-top: rem-calc(154);
                padding-bottom: rem-calc(140);
            }
        }
        .webs & {
            background-color: $webs;
        }
        .treballa & {
            background-color: #24bdf2;
        }
        .salut & {
            background-color: $salut;
        }
        .labs & {
            background-color: #65ccd8;
        }
        .recerca & {
            background-color: $recerca;
            a {
                color: $white;
            }
        }
        .intranets & {
            background-color: $intranets;
        }
        .eines-a-mida & {
            background-color: $eines-a-mida;
        }
        .cms & {
            background-color: $cms;
        }
        .dg & {
            background-color: $dg;
        }
        .illustracio & {
            background-color: $illustracio;
        }
        .tipo & {
            background-color: $tipografia;
        }
        .infografies & {
            background-color: $infografies;
        }
        .feines & {
            background-color: $darker-gray;
        }
        .que & {
            background-color: #65ccd8;
        }
    }

    // contenidor breadcrumb + apartat
    &--location {
        padding-top: rem-calc(40);
        padding-bottom: rem-calc(50);
        /*background: #dedede;*/
        @media #{$medium-only} {
            padding-top: rem-calc(140) !important;
        }
        @media #{$medium-up} {
            padding-top: rem-calc(100);
            padding-bottom: rem-calc(80);
        }

        .view & {
            padding-bottom: 0;
        }

        h2 {
            display: none;
            @media #{$medium-up} {
                @include visually-hidden;
            }
        }
    }

    // qui som
    &--who {
        &-0 {
            padding-bottom: rem-calc(0);
        }
        &-1 {
            //background: #4b7373;
            //background-image: linear-gradient(to bottom, #4b7373, #68d0dd);
            position: relative;
            //padding-top: rem-calc(120);
            @media #{$medium-up} {
                //padding-top: rem-calc(165);
            }
            * {
                color: #000;
            }
            a:hover {
                color: #000;
            }
            .img-picto {
                margin-bottom: 40px;
            }
            /*&:before {
                @extend .pict-somantaviana-small;
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                top: -$pict-somantaviana-small-height/2;
                margin-left: -$pict-somantaviana-small-width/2;
                @media #{$medium-up} {
                    display: none;
                }
            }*/
            /*&:after {
                display: none;
                @extend .pict-somantaviana;
                @media #{$medium-up} {
                    display: block;
                    content: '';
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: -$pict-somantaviana-height/2;
                    margin-left: -$pict-somantaviana-width/2;
                }
            }*/
        }
        &-2 {
            background-color: $darker-gray;
            * {
                color: $white;
            }
            @media #{$medium-up} {
                padding-bottom: rem-calc(800);
                background-position: 0 100%;
                background-image: url("img/som-img-1.jpg");
            }
            @media #{$xxlarge-up} {
                padding-bottom: rem-calc(1300);
            }
        }
        &-3 {
            background-color: #f9f9f9;
        }
        &-8 {
            background-color: #24bdf2;
            padding: 0;
            * {
                color: $white;
            }
            @media #{$medium-up} {
                //padding-bottom: rem-calc(800);
            }
            @media #{$xxlarge-up} {
                //padding-bottom: rem-calc(1300);
            }
        }
        &-4 {
            background: #000;
            //background-image: linear-gradient(to bottom, #505050, #777777);

            p {
                color: $white;
            }
        }
        &-treball {
            background-color: #24bdf2;
            padding: 0;
            * {
                color: $white;
            }
        }
    }

    // què fem
    &--que-fem {
        &-2 {
            padding-top: 0;

            // @media #{$medium-up} {
            //     padding-top: rem-calc(90);
            // }
        }
    }

    // webs
    &--webs {
        /*background: $webs;*/
        &-3 {
            background-color: $webs;
            * {
                color: black;
            }
            @media #{$medium-up} {
                /*min-height: 100vh;
                background-image: url('img/webs-img-1.jpg');*/
                background-color: $webs;
            }
            /*[lang="en"] & {
                .info-box {
                   border: 1px solid $white;
                   display: block;
                   text-decoration: none;
                   padding: rem-calc(35);
                   background-color: rgba(0,0,0,.5);
                   transition: background-color .1s ease-in;
                   margin: rem-calc(5 20);
                   height: rem-calc(140);
                   h2 {
                        text-align: center;
                       }
                }
            }*/
        }
        &-4 {
            background: black;
            * {
                color: $white;
            }
            @media #{$medium-up} {
                padding: rem-calc(200) 0;
                /*[lang="en"] & {
                    .info-box {
                       h2 {
                        margin: rem-calc(0 50 0 50);
                        text-align: center;
                       }
                       &--icon{
                        &:before {
                            left: 0;
                            right: 0;
                        }
                       }
                    }
                }*/
            }
        }
        &-5 {
            background-color: $darker-gray;
            * {
                color: $white;
            }
            @media #{$medium-up} {
                min-height: 100vh;
                background-image: url("img/webs-img-2.jpg");
                /*[lang="en"] & {
                    .info-box {
                       border: 1px solid $white;
                       display: block;
                       text-decoration: none;
                       padding: rem-calc(35);
                       background-color: rgba(0,0,0,.5);
                       transition: background-color .1s ease-in;
                       margin: rem-calc(5 20);
                       height: rem-calc(140);
                       &--icon {
                        &:before {
                            margin: rem-calc(20);
                        }
                       }
                       h2 {
                            margin: rem-calc(25 0 0 150);
                       }
                    }

                }*/
            }
        }
        &-6 {
            background: $webs;
            @media #{$medium-up} {
                /*[lang="en"] & {
                    .info-box {
                       h2 {
                        margin: rem-calc(0 50 0 50);
                        text-align: center;
                       }
                       &--icon{
                        &:before {
                            left: 0;
                            right: 0;
                        }
                       }
                    }
                }*/
            }
        }
        &-7 {
            @media #{$medium-up} {
                /*[lang="en"] & {
                    .info-box--estrategia {
                      h2 {
                        margin: rem-calc(0 50 0 50);
                        text-align: center;
                       }
                    }
                    .info-box {
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                    .info-box--metodologia {
                        &:before {
                            margin-left: auto;
                        }
                    }
                }*/
            }
        }
        &-8 {
            background: $lighter-gray;
            p {
                color: black;
                //@include light;
                font-size: rem-calc(21);
                line-height: 1.4;
                margin-bottom: rem-calc(55);
            }
            h2 {
                color: black;
            }
        }
        &-10 {
            .button {
                border-color: black;
                color: black;
                &:hover {
                    border-color: black;
                    background: black;
                    color: white !important;
                }
            }
        }
    }

    // com ho fem
    &--com {
        background: $webs;
        &-3 {
            padding-top: 0;
            * {
                color: $white;
            }
        }
        &-4 {
            background: $light-gray;
        }
    }

    // intranets
    &--intranets {
        background: $intranets;
        &-1 {
            background: black;
        }
        &-2 {
            p {
                color: black;
            }
            h2 {
                &.h-c {
                    color: black;
                }
            }
            .info-box {
                h3 {
                    color: black;
                }
            }
        }
        /*&-2 {
            @media #{$medium-up} {
                [lang="en"] & {
                    .info-box {
                        h3 {
                            margin: rem-calc(0 50 0 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }
            }
        }*/
        &-3 {
            background: $intranets;
            p {
                color: black;
                &:before {
                    @extend .ico-intranets-integracio-eines;
                    content: "";
                    display: block;

                    margin: 0 auto $icon-margin-bottom + px;
                }
                @media #{$medium-up} {
                    padding-left: $ico-intranets-integracio-eines-width + 58;
                    position: relative;
                    text-align: left;
                    &:before {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        margin-bottom: 0;
                        /*[lang="en"] & {
                            top: rem-calc(-150);
                        }*/
                    }
                }
            }
            h2 {
                &.h-c {
                    color: black;
                }
            }
            /*[lang="en"] & {
                @media #{$medium-up} {
                    h2 {
                       padding-left: rem-calc(80);
                       padding-top: rem-calc(50);
                       &.h-c {
                        margin-bottom: 1.25rem;
                       }
                    }
                }
            }*/
        }
        /*&-4 {
            [lang="en"] & {
                    .info-box {
                        h2 {
                            margin: rem-calc(0 50 0 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }
        }*/
        &-4 {
            .info-box {
                h2 {
                    &.h-a {
                        color: black;
                    }
                }
                p {
                    color: black;
                }
            }
        }
        &-5 {
            background-color: $darker-gray;
            * {
                color: $white;
            }
            @media #{$medium-up} {
                // background-attachment: fixed;
                min-height: 100vh;
                background-image: url("img/intranets-img-1.jpg");
                background-position: 100% 0;
                background-size: cover;
                @media #{$retina} {
                    background-image: url("img/intranets-img-1@2x.jpg");
                }
            }
            /*[lang="en"] & {
                    .info-box {
                        h2 {
                            margin: rem-calc(0 50 0 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }*/
        }
        &-6 {
            background: #000;
            .info-box {
                h2 {
                    color: $white;
                }
                p {
                    color: $white;
                }
            }
        }
    }

    // serveis de disseny
    &--serveid {
        &-1 {
            background: #f5f3ed;
        }
        &-2 {
            background-color: #ff6f6f;
            p {
                color: black;
                a {
                    color: white;
                }
            }
            h2 {
                color: white;
            }
            .info-box {
                h3 {
                    color: black;
                }
            }
        }
        &-3 {
            background-color: #5fc2d9;
            p {
                color: black;
                a {
                    color: white;
                }
            }
            h2 {
                color: white;
            }
        }

        &-4 {
            background-color: #fff;
        }
    }

    // eines a mida
    &--eines-a-mida {
        /*background: $eines-a-mida;*/
        &-3 {
            /*background-color: $darker-gray;*/
            background-color: #000;
            .info-box h2 {
                color: #fff;
            }
            * {
                color: #fff;
            }
            @media #{$medium-up} {
                // background-attachment: fixed;
                /*min-height: 100vh;*/
                /*background-image: url('img/eines-img-1.jpg');*/
                /*[lang="en"] & {
                    .info-box {
                        h2, p {
                            margin: rem-calc(0 50 15 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }*/
            }
        }
        &-4 {
            padding-bottom: 0;
            .button {
                border-color: black;
                color: black;
                &:hover {
                    background: black;
                    color: white;
                }
            }
            h2 {
                &.h-c {
                    color: black;
                }
            }
            p {
                color: black;
            }
        }
    }

    // gestors de continguts
    &--cms {
        background: $cms;
        * {
            color: black;
        }
        &-3 {
            background-color: $darker-gray;
            * {
                color: $white;
            }
            @media #{$medium-up} {
                min-height: 100vh;
                background-image: url("img/cms-img-1.jpg");
                padding-bottom: rem-calc(600);
                * {
                    color: #4a4a4a;
                }
            }
        }
        &-4 {
            background-color: black;
            //background-image: linear-gradient(to bottom, #333, #676767);
            * {
                color: $white;
            }
        }
    }

    // comunicacio
    &--comunicacio {
        background: $comunicacio;
        * {
            color: black;
        }
        &-3 {
            background-color: $darker-gray;
            * {
                color: black;
                margin-bottom: 0;
            }
            @media #{$medium-up} {
                // background-attachment: fixed;
                min-height: 100vh;
                padding-bottom: rem-calc(800);
                background-image: url("img/comunicacio-img-1.jpg");
            }
            .lg.icon {
                &:before {
                    @extend .ico-comunicacio-principal;
                    content: "";
                    display: block;
                    margin: 0 auto;
                    margin-bottom: $icon-margin-bottom + px;
                }
            }
        }
        &-4 {
            * {
                color: black;
            }
        }
        /* &-4 {
            @media #{$medium-up} {
                [lang="en"] & {
                    .info-box {
                        h2, p {
                            margin: rem-calc(0 50 15 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }
            }
        }*/
        &-5 {
            background-color: black;
            //background-image: linear-gradient(to bottom, #505050, #777);
            * {
                color: $white;
            }
            /*@media #{$medium-up} {
                [lang="en"] & {
                    .info-box {
                        h2, p {
                            margin: rem-calc(0 50 15 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }
            }*/
        }
        &-6 {
            * {
                color: black;
            }
            .button {
                border-color: black;
                color: black;
                &:hover {
                    background: black;
                    border-color: black;
                    color: white;
                }
            }
        }
    }

    // disseny gràfic
    &--dg {
        background: $dg;
        &-6 {
            .button {
                border-color: black;
                color: black;
                &:hover {
                    background: black;
                    color: white;
                    border-color: black;
                }
            }
        }
    }

    // logos
    &--logo {
        @media #{$medium-up} {
            height: rem-calc(400);
            position: relative;
            padding: 0;
            line-height: rem-calc(400);
        }
        img {
            vertical-align: middle;
        }
        &-1 {
            background-color: #bc0c29;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 155px) / 2;
                }
            }
        }
        &-2 {
            background-color: #ffcc00;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 180px) / 2;
                }
            }
        }
        &-3 {
            background-color: #fff;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 197px) / 2;
                }
            }
        }
        &-4 {
            background-color: #4c4c4c;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 161px) / 2;
                }
            }
        }
        &-5 {
            background-color: #e6e6dd;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 185px) / 2;
                }
            }
        }
        &-6 {
            background-color: #fff;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 232px) / 2;
                }
            }
        }
        &-7 {
            background-color: #00b8de;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 57px) / 2;
                }
            }
        }
        &-8 {
            background-color: #fff;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 311px) / 2;
                }
            }
        }
        &-9 {
            background-color: #71a8df;
            @media #{$medium-up} {
                img {
                    margin-top: (400px - 123px) / 2;
                }
            }
        }

        img {
            margin-bottom: 0;
        }
    }

    // il·lustració
    &--il {
        background: $illustracio;
        &-4 {
            padding-bottom: 0;
            background-color: #d7d5b5;
        }
        &-6 {
            background-color: $light-gray;
            h2 {
                &.h-c {
                    color: black;
                }
            }
            ul {
                li {
                    color: black;
                }
            }
        }
        &-7 {
            text-align: left;
        }
        &-8 {
            .button {
                color: black;
                border-color: black;
                &:hover {
                    border-color: black;
                    background: black;
                    color: white;
                }
            }
        }
    }

    // tipografia
    &--tipo {
        background: $tipografia;
        &-3 {
            background-color: $tipografia;
            h2 {
                margin-bottom: $paragraph-margin-bottom;
                @media #{$medium-up} {
                    text-align: left;
                }
            }
            .font {
                float: right;
                margin-top: rem-calc(15);
            }
            * {
                color: black;
            }
            /*@media #{$medium-up} {
                min-height: 100vh;
                background-image: url('img/tipo-img-1.jpg');
                @media #{$retina} {
                    background-image: url('img/tipo-img-1@2x.jpg');
                }
            }*/
            .table {
                color: black;
                .table-cell {
                    outline: black solid 1px;
                }
            }
        }
        &-4 {
            /* @media #{$medium-up} {
                [lang="en"] & {
                    .info-box {
                        h2, p {
                            margin: rem-calc(0 50 15 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }
            }*/
        }
        &-5 {
            .button {
                border-color: black;
                color: black;
                &:hover {
                    color: white;
                    border-color: black;
                    background: black;
                }
            }
        }
    }

    // infografies
    &--infografies {
        background: $infografies;
        &-1 {
            background: black;
        }
        &-3 {
            * {
                color: black;
            }
        }
        &-4 {
            background: black;
            * {
                color: $white;
            }
            /*@media #{$medium-up} {
                [lang="en"] & {
                    .info-box {
                        h2 {
                            margin: rem-calc(0 50 15 50);
                            text-align: center;
                        }
                        &--icon {
                            &:before {
                                left: 0;
                                right: 0;
                            }
                       }
                    }
                }
            }*/
        }
        &-7 {
            .button {
                border-color: black;
                color: black;
                &:hover {
                    color: white;
                    border-color: black;
                    background: black;
                }
            }
        }
    }

    // testimonis
    &--testimonis {
        padding-bottom: 0;
        background: #dedede;
        background-image: linear-gradient(to bottom, #ffffff, #dedede);
    }

    // feines

    &--case {
        padding-top: 0;

        &-1 {
            background-color: #90282a;
        }

        &-2 {
            background-color: #f3f1d6;
        }

        &-3 {
            background-color: #971e37;
        }
        /* &-4 {
            [lang="en"] & {
                display: none;
            }
        }*/
        &-salut-4 {
            padding-top: 0;
        }
        &-5 {
            background-color: #f5f5f5;
        }
        &-6 {
            background-color: #4a90e2;
        }
        &-cert-intro {
            margin-top: 4em;
            padding-top: 4em;
            background: none;
            background-color: #dedede !important;
            padding-bottom: 9em;
            h1 {
                font-size: 3.3rem;
                text-transform: none;
            }
            .deco__img {
                position: absolute;
                bottom: -3rem;
                left: 45%;
            }
        }
        &-cert-7 {
            padding: 3rem 0;
            background-color: #78cc82;
            h2 {
                font-size: 3.3rem;
                font-weight: 300;
                color: #fff;
            }
            h3 {
                font-size: 2.3rem;
                color: #fff;
            }
            &.decorat {
                padding-top: 6em;
            }
            &.botons {
                p {
                    color: #fff;
                    font-size: 1.8em;
                    font-weight: 300;
                    span {
                        font-size: 0.9em;
                        &.petit {
                            font-size: 0.7em;
                        }
                    }
                }
                h3 {
                    font-weight: bold;
                    font-size: 2.3rem;
                    color: #000000;
                }
                .button-white {
                    border-color: #fff;
                    color: #fff;
                }
            }
        }
        &-cert-blanc {
            padding: 3rem 0;
            background-color: #fff;
            h3 {
                font-size: 2.3rem;
                margin-bottom: 1.3em;
            }
            ul,
            p {
                text-align: justify;
            }
        }
        &-cert-8 {
            padding: 3rem 0;
            background-color: #676767;
            color: #fff;
            h3 {
                color: #fff;
                font-size: 2.3rem;
            }
            img {
                margin: 3em 0;
            }
        }
        &-cert-9 {
            padding: 3rem 0;
            background-color: #efefef;
            h3 {
                font-size: 2.3rem;
                margin-bottom: 1.3em;
            }
            .caixeta {
                position: relative;
                img {
                    position: absolute;
                    left: 0.625rem;
                }
                div.left {
                    margin-top: 8em;
                    p {
                        text-align: left;
                    }
                }
            }
        }
    }
    .llista-feines {
        list-style: none;
        margin-top: rem-calc(54);
        li {
            display: inline-block;
            margin-left: rem-calc(15);
            margin-right: rem-calc(15);
        }
    }
}
.casos {
    /*[lang="en"] & {
                display: none;
            }*/
}
