.logos-list {
    margin: 0 0 $paragraph-margin-bottom;
    list-style: none;
    font-size: 0;
    text-align: center;
}
.logos-list__item {
    margin: $paragraph-margin-bottom $paragraph-margin-bottom*2;
    display: inline-block;
    vertical-align: middle;

    .sr & {
        visibility: hidden;
    }


    // logos clients
    &--mwc {
        @extend .logo-mwc;
    }
    &--firatarrega {
        @extend .logo-firatarrega;
    }
    &--clinic {
        @extend .logo-clinic;
    }
    &--idibaps {
        @extend .logo-idibaps;
    }
    &--bst {
        @extend .logo-bst;
    }
    &--csc {
        @extend .logo-csc;
    }
    &--enginyers {
        @extend .logo-enginyers;
    }
    &--miro {
        @extend .logo-miro;
    }
    &--igtp {
        @extend .logo-igtp;
    }
    &--mediapro {
        @extend .logo-mediapro;
    }
    &--ics {
        @extend .logo-ics;
    }
    &--mar {
        @extend .logo-mar;
    }
    &--diputaciobcn {
        @extend .logo-diputaciobcn;
    }
    &--bib {
        @extend .logo-bib;
    }
    &--diplocat {
        @extend .logo-diplocat;
    }
    &--mercabarna {
        @extend .logo-mercabarna;
    }
    &--dali {
        @extend .logo-fundacio-dali;
    }
    &--diabalance {
        @extend .logo-diabalance;
    }
    &--carreras {
        @extend .logo-fundacion-carreras;
    }

    //logos salut
    &--clinic-salut {
        @extend .logo-clinic-salut;
    }
    &--mar-salut {
        @extend .logo-hospital-del-mar;
    }
    &--ics-salut {
        @extend .logo-ics-salut;
    }
    &--gencat {
        @extend .logo-salut-gencat;
    }
    &--bellvitge {
        @extend .logo-bellvitge-hospital;
    }
    &--barnaclinic {
        @extend .logo-barnaclinic;
    }
    &--bancsang {
        @extend .logo-bancsang;
    }
    &--bancsang-illes {
        @extend .logo-bancsang-illes;
    }
    &--chv {
        @extend .logo-chv;
    }
    &--csi {
        @extend .logo-csi;
    }
    &--cs-maresme {
        @extend .logo-cs-maresme;
    }
    &--csc-salut {
        @extend .logo-csc-salut;
    }
    &--santceloni {
        @extend .logo-hospital-santceloni;
    }
    &--granollers {
        @extend .logo-hospital-granollers;
    }
    &--esperitsant {
        @extend .logo-hospital-esperitsant;
    }

    //logos recerca
     &--carreras-recerca {
        @extend .logo-fundacio-carreras;
    }
    &--cellab {
        @extend .logo-cellab;
    }
    &--idibaps-recerca {
        @extend .logo-idibaps-recerca;
    }
    &--icn2 {
        @extend .logo-icn2;
    }
    &--creal {
        @extend .logo-creal;
    }
    &--irblleida {
        @extend .logo-irblleida;
    }
    &--igtp-recerca {
        @extend .logo-igtp-recerca;
    }
    &--icfo {
        @extend .logo-icfo;
    }
    &--idibgi {
        @extend .logo-idibgi;
    }
    &--fsjd {
        @extend .logo-fsjd;
    }
    &--bib-recerca {
        @extend .logo-bib-recerca;
    }
    &--irta {
        @extend .logo-irta;
    }
    &--enerca {
        @extend .logo-enerca;
    }
    &--imppc {
        @extend .logo-imppc;
    }
    &--maragall {
        @extend .logo-fundacio-maragall;
    }
    &--cmrb {
        @extend .logo-cmrb;
    }
    &--imim {
        @extend .logo-imim;
    }
}
