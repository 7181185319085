.work {
    @include clearfix;
    margin-bottom: $paragraph-margin-bottom;
    margin-top: $paragraph-margin-bottom;
    &__item {
        float: left;
        width: 100%;
        padding: 15px;
        .sr & {
            visibility: hidden;
        }

        @media #{$medium-up} {
            width: 50%;
            //margin-bottom: rem-calc(70);
        }

        @media #{$large-up} {
            width: 25%;
        }
    }

    &__link {
        text-decoration: none;
        display: block;
        position: relative;
        background: 50% 45px no-repeat;
        padding: rem-calc(155 30 50);
        transition: background-color .1s ease-in;
        border-radius: 15px;
        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 45px;
        }

        /*&:after {
            content: '';
            width: 80%;
            height: 1px;
            background: $gray;
            position: absolute;
            left: 50%;
            bottom: -33px;
            margin-left: -40%;
        }*/

        &--1 {
            border: 1px solid $webs;
            &:before {
                @extend .ico-quefem-portada-webs;
                margin-left: -$ico-quefem-portada-webs-width/2;
            }
            &:hover,
            &:focus {
                background-color: $webs;
            }
        }
        &--2 {
            border: 1px solid $intranets;
            &:before {
                @extend .ico-quefem-portada-intranets;
                margin-left: -$ico-quefem-portada-intranets-width/2;
            }
            &:hover,
            &:focus {
                background-color: $intranets;
            }
        }
        &--3 {
            border: 1px solid $eines-a-mida;
            &:before {
                @extend .ico-quefem-portada-eines;
                margin-left: -$ico-quefem-portada-eines-width/2;
            }
            &:hover,
            &:focus {
                background-color: $eines-a-mida;
            }
        }
        &--4 {
            border: 1px solid $tipografia;
            &:before {
                @extend .ico-quefem-portada-apps;
                margin-left: -$ico-quefem-portada-apps-width/2;
            }
            &:hover,
            &:focus {
                background-color: $tipografia;
            }
        }
        &--5 {
            border: 1px solid $cms;
            &:before {
                @extend .ico-quefem-portada-gestors;
                margin-left: -$ico-quefem-portada-gestors-width/2;
            }
            &:hover,
            &:focus {
                background-color: $cms;
            }
        }
        &--6 {
            border: 1px solid $dg;
            &:before {
                @extend .ico-quefem-portada-disseny;
                margin-left: -$ico-quefem-portada-disseny-width/2;
                top: 20px;
            }
            &:hover,
            &:focus {
                background-color: $dg;
            }
        }
        &--7 {
            border: 1px solid $illustracio;
            &:before {
                @extend .ico-quefem-portada-illustracio;
                margin-left: -$ico-quefem-portada-illustracio-width/2;
            }
            &:hover,
            &:focus {
                background-color: $illustracio;
            }
        }
        &--8 {
            border: 1px solid $infografies;
            &:before {
                @extend .ico-quefem-portada-infografies;
                margin-left: -$ico-quefem-portada-infografies-width/2;
            }
            &:hover,
            &:focus {
                background-color: $infografies;
            }
        }
        &--9 {
            border: 1px solid $comunicacio;
            &:before {
                @extend .ico-quefem-portada-comunicacio;
                margin-left: -$ico-quefem-portada-comunicacio-width/2;
            }
            &:hover,
            &:focus {
                background-color: $comunicacio;
            }
        }

        &:hover,
        &:focus {
            text-decoration: none;

            .work__title {
                color: #000;
            }
        }

        @media #{$medium-up} {
            padding-bottom: rem-calc(0);
        }
    }

    &__title {
        color: #000;
        font-size: rem-calc(18);
        @include demibold;
        //text-transform: uppercase;
    }

    p {
        @include light;
        font-size: rem-calc(13);
        line-height: 1.2;
        color: #000;
        padding-bottom: $paragraph-margin-bottom;
    }
}
