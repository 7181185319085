.slider {
    margin: rem-calc(60 0);

    @media #{$medium-up} {
        margin: rem-calc(90 0);
    }
}

@media #{$xlarge-up} {
    .slick-container {
        @include grid-column(12);
        border: 1px solid red;
    }
}

// .slick-slide {
//     background-color: $gray;
// }

// botons prev/next
.slick-arrow {

    // ocultar a mòbil
    @media #{$small-only} {
        display: none !important;
    }

    border-radius: $global-rounded;
    text-align: center;

    &:before {
        content: '';
        display: inline-block;
    }
}

@media #{$medium-only} {
    .slick-prev {
        left: -90px;

    }
    .slick-next {
        right: -90px;
    }
}

@media #{$medium-up} {
    .slick-prev {
        left: -95px;

    }
    .slick-next {
        right: -95px;
    }
}

