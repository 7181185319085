.skip-link {
    position: absolute;
    top: rem-calc(20);
    right: 100%; /* moves off screen */
    background-color: $white;
    padding: 1rem;
    font-size: rem-calc(14);

    &:focus {
        right: auto;
        left: rem-calc(20);
        z-index: 3;
    }
}
