@import "normalize";

// Foundation imports
// ------------------

// custom Foundation settings (file copied from 'bower_components/foundation/scss/foundation/_settings.scss')
@import "settings";

// import from 'bower_components/foundation/scss/'
// @import 'foundation';

// or import selectively
@import "foundation/components/grid";
// @import 'foundation/components/accordion';
// @import 'foundation/components/alert-boxes';
@import "foundation/components/block-grid";
// @import 'foundation/components/breadcrumbs';
// @import 'foundation/components/button-groups';
@import "foundation/components/buttons";
// @import 'foundation/components/clearing';
// @import 'foundation/components/dropdown';
// @import 'foundation/components/dropdown-buttons';
// @import 'foundation/components/flex-video';
@import "foundation/components/forms";
// @import 'foundation/components/icon-bar';
// @import 'foundation/components/inline-lists';
// @import 'foundation/components/joyride';
// @import 'foundation/components/keystrokes';
// @import 'foundation/components/labels';
// @import 'foundation/components/magellan';
// @import 'foundation/components/orbit';
// @import 'foundation/components/pagination';
// @import 'foundation/components/panels';
// @import 'foundation/components/pricing-tables';
// @import 'foundation/components/progress-bars';
// @import 'foundation/components/range-slider';
// @import 'foundation/components/reveal';
// @import 'foundation/components/side-nav';
// @import 'foundation/components/split-buttons';
// @import 'foundation/components/sub-nav';
// @import 'foundation/components/switches';
@import "foundation/components/tables";
// @import 'foundation/components/tabs';
// @import 'foundation/components/thumbs';
// @import 'foundation/components/tooltips';
// @import 'foundation/components/top-bar';
@import "foundation/components/type";
// @import 'foundation/components/offcanvas';
@import "foundation/components/visibility";

// Lib
// ---
@import "../../bower_components/magnific-popup/src/css/main.scss";
@import "../../node_modules/animate.css/animate.css";

// Sprites
// -------

@import "sprites";
@include sprites($spritesheet-sprites);
// Creates all retina sprites
@include retina-sprites($retina-groups);

// Custom imports
// --------------

// utilitites
@import "utilities/mixins";
@import "utilities/variables";
@import "utilities/utilities";
@import "utilities/print";

// base
//@import "base/fonts";
@import 'base/googleFonts_Barlow';
@import 'base/googleFonts_Kalnia';
@import "base/type";

// components
@import "components/layout";
@import "components/base"; // pàgina bàsica
@import "components/header";
@import "components/header-video";
@import "components/skip"; // skip link
@import "components/footer";
@import "components/breadcrumb";
@import "components/ico-gif";
@import "components/mobile-menu";
@import "components/main-nav";
@import "components/buttons";
@import "components/floor";
@import "components/home-animation";
@import "components/info-box";
@import "components/team";
@import "components/grid"; // home grid
@import "components/grid-imatges";
@import "components/work";
@import "components/utils-intranet";
@import "components/utils-infografies";
@import "components/testimonis";
@import "components/cookies";
@import "components/logos-list";
@import "components/social-list";
@import "components/icons-list";
@import "components/workflow"; // esquema "Com treballem"
@import "components/figure";
@import "components/destacat";
@import "components/contact";
@import "components/skip-links";
@import "components/case"; //mostrari de feines
@import "components/case-view"; //fitxes de feina
@import "components/close-icon";
@import "components/top"; //fletxa per tornar amunt
@import "components/table"; //fletxa per tornar amunt
@import "components/slick"; // Fitxer copiat de /bower_components/slick-carousel/slick/
@import "components/slick-theme"; // Fitxer copiat de /bower_components/slick-carousel/slick/
@import "components/slick-custom"; // Modificacions al slick-carousel
@import "components/404";
@import "components/ihavecookies";
@import "components/legals";

// pages
@import "pages/serveis-disseny";
@import "pages/canal-denuncies";
