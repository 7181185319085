.breadcrumb {
    display: none;

    @media #{$medium-up} {
        @include demibold;
        display: block;
        text-align: center;
        font-size: rem-calc(10);
        margin: rem-calc(0 0 60);
        list-style: none;
        text-transform: uppercase;
        letter-spacing: 1.8px;

        .floor--location & {
            margin-bottom: rem-calc(40);
        }

        .base &,
        .som &,
        .feines &,
        .feines-fitxa &,
        .salut &,
        .recerca &,
        .labs &,
        .sub-home & {
            .no-js & {
                margin-top: 2em;
            }
            .js & {
                margin-top: 10em;
                @media #{$medium-only} {
                    margin-top: 14em;
                }
            }
        }
    }

}
.breadcrumb__item {
    display: none;

    @media #{$medium-up} {
        display: inline-block;

        &:after {
            content: '\003E';
        }

        &:last-child:after {
            content: '';
        }

        a {
            @include hover;
            color: $body-font-color;
        }

        a,
        span {
            padding: 0 2em;
        }
    }
}
