// mixins

// Triangle
// --------
// We use this to create isosceles triangles
// - $triangle-size       - Used to set border-size. No default, set a px or em size.
// - $triangle-color      - Used to set border-color which makes up triangle. No default
// - $triangle-direction  - Used to determine which direction triangle points.
//                          Options: top, bottom, left, right
@mixin triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if $triangle-direction == top {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if $triangle-direction == bottom {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if $triangle-direction == left {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if $triangle-direction == right {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

// visually hidden
@mixin visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

// underline on hover
@mixin hover {
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}

// type
@mixin bridone {
  font-family: 'Kalnia', $font-family-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin book {
  font-family: 'Barlow', $font-family-sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin demibold {
  font-family: 'Barlow', $font-family-sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin heavy {
  font-family: 'Barlow', $font-family-sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin light {
  font-family: 'Barlow', $font-family-sans-serif;
  font-weight: 300;
  font-style: normal;
}

// darken bg color on hover ("Altres feines")
@mixin darken($box-color, $amount:2%) {
    &:hover {
        background-color: darken($box-color, $amount);
    }
}
