.destacat {
    border: 1px solid $white;
    .home-video & {
                position: absolute;
                z-index:1;
                @media #{$medium-up} {
                    bottom: 30px;
                    font-size: rem-calc(26);
                }
                @media #{$large-up} {
                    bottom: 100px;
                    font-size: rem-calc(52);
                }
            }
    // destacat contacte
    &--contact {
        margin-bottom: $paragraph-margin-bottom;
        padding: rem-calc(20 30);
        @media #{$medium-up} {
            margin-bottom: rem-calc(65);
        }

        &__title {
            @include light;
            font-size: rem-calc(22);
            color: $white;
            margin-bottom: rem-calc(17);
            @media #{$medium-up} {
                font-size: rem-calc(32);
                margin-bottom: rem-calc(12);
            }
        }

        &__text {
            @include light;
            font-size: rem-calc(14);
            color: $white;
        }
    }

    // destacat home
    &--home {
        a {
            display: block;
            text-decoration: none;
            padding: rem-calc(35);
            background-color: rgba(0,0,0,.5);
            transition: background-color .1s ease-in;
            .home-video & {
                text-align: center;
                background-color: rgba(0,0,0,.3);
                @media #{$medium-up} {
                    padding: rem-calc(20);
                }
                @media #{$large-up} {
                    padding: rem-calc(35);
                }
            }

            [lang="es"] & {
                cursor: default; // provisional
            }

            &:hover {
                background-color: rgba(0,0,0,.6);
                [lang="es"] & {
                    background-color: rgba(0,0,0,.42); // provisional
                }
            }
        }

        &__title {
            @include bridone;
            font-size: rem-calc(33);
            color: $white;
            line-height: 1.1;

            .contact & {
                @include light;
            }
            .home-video & {
                @media #{$medium-up} {
                    font-size: rem-calc(25);
                }
                @media #{$large-up} {
                    font-size: rem-calc(33);
                }
            }
        }

        &__text {
            @include book;
            font-size: rem-calc(14)!important;
            //color: #eac996;
            color: $white;
            width: 100%!important;
            .home-video & {
                color: #fff;
            }
            .contact & {
                color: $white;
            }
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
}
