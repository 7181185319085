// home grid
$top: 260; //distància del .grid__descr fins a la part superior del .grid__item

.grid {
    @include clearfix;

    // grid 3 items
    &__item {
        position: relative;
        height: rem-calc(412);

        &--webs a {
            background-color: $home-webs;
            &:before {
                @extend .ico-webs-home;
                margin-left: -$ico-webs-home-width/2;
                // top: ($top - $ico-webs-home-height) / 2;
            }
        }
        &--intranets a {
            background-color: $home-intranets;
            &:before {
                @extend .ico-intranets-home;
                margin-left: -$ico-intranets-home-width/2;
                // top: ($top - $ico-intranets-home-height) / 2;
            }
        }
        &--comunicacio a {
            background-color: $home-comunicacio;
            &:before {
                @extend .ico-comunicacio;
                margin-left: -$ico-comunicacio-width/2;
                top: ($top - $ico-comunicacio-height) / 2;
            }
        }
        &--eines-a-mida a {
            background-color: $home-eines-a-mida;
            &:before {
                @extend .ico-einesamida-home;
                margin-left: -$ico-einesamida-home-width/2;
                // top: ($top - $ico-einesamida-home-height) / 2;
            }
        }
        &--cms a {
            background-color: $home-cms;
            &:before {
                @extend .ico-gestors;
                margin-left: -$ico-gestors-width/2;
                top: ($top - $ico-gestors-height) / 2;
            }
        }
        &--dg a {
            background-color: $home-dg;
            &:before {
                @extend .ico-disseny;
                margin-left: -$ico-disseny-width/2;
                top: ($top - $ico-disseny-height) / 2;
            }
        }
        &--tipografia a {
            background-color: $home-tipografia;
            &:before {
                @extend .ico-tipografia;
                margin-left: -$ico-tipografia-width/2;
                top: ($top - $ico-tipografia-height) / 2;
            }
        }
        &--illustracio a {
            background-color: $home-illustracio;
            &:before {
                @extend .ico-ilustracio;
                margin-left: -$ico-ilustracio-width/2;
                top: ($top - $ico-ilustracio-height) / 2;
            }
        }
        &--infografies a {
            background-color: $home-infografies;
            &:before {
                @extend .ico-infografies-home;
                margin-left: -$ico-infografies-home-width/2;
                top: ($top - $ico-infografies-home-height) / 2;
            }
        }
        &--apps a {
            background-color: $home-app;
            &:before {
                @extend .ico-progressive-web-apps-home;
                margin-left: -$ico-progressive-web-apps-home-width/2;
                top: ($top - $ico-progressive-web-apps-home-height) / 2;
            }
        }
        &--denuncies a {
            background-color: $home-denuncies;
            &:before {
                @extend .ico-denuncies-home;
                margin-left: -$ico-denuncies-home-width/2;
                // top: ($top - $ico-denuncies-home-height) / 2;
            }
        }
        &--blog a {
            padding-top: rem-calc(32);
            background-color: $home-blog;
        }

        // nadala CATN
        &--catn a {
            background: #f3f3f3;
            background: linear-gradient(180deg, #f3f3f3 0%, #ffffff 100%);
            &:before {
                @extend .logo-catn-inici;
                margin-left: -$logo-catn-inici-width/2;
                top: ($top - $logo-catn-inici-height) / 2;
            }
        }

        // feines
        &--endp a {
            background-color: #f3f1d6;
            &:before {
                @extend .caixeta-endp;
                margin-left: -$caixeta-endp-width/2;
                top: ($top - $caixeta-endp-height) / 2;
            }
        }
        &--orto a {
            background-color: #5da8c3;
            &:before {
                @extend .caixeta-ortoarea;
                margin-left: -$caixeta-ortoarea-width/2;
                top: ($top - $caixeta-ortoarea-height) / 2;
            }
        }
        &--sang a {
            background-color: #bc2b30;
            &:before {
                @extend .logo-bancsang-portada;
                margin-left: -$logo-bancsang-portada-width/2;
                top: ($top - $logo-bancsang-portada-height) / 2;
            }
        }
        &--cido a {
            background-color: #971e37;
            &:before {
                @extend .caixeta-cido;
                margin-left: -$caixeta-cido-width/2;
                top: ($top - $caixeta-cido-height) / 2;
            }
        }
        &--igtp a {
            background-color: #4a90e2;
            &:before {
                @extend .caixeta-igtp;
                margin-left: -$caixeta-igtp-width/2;
                top: ($top - $caixeta-igtp-height) / 2;
            }
        }
        &--urv a {
            background-color: #90282a;
            &:before {
                @extend .caixeta-urv;
                margin-left: -$caixeta-urv-width/2;
                top: ($top - $caixeta-urv-height) / 2;
            }
        }
        &--disseny a {
            background-color: #95d1e0;
            &:before {
                @extend .ico-disseny-portada;
                margin-left: -$ico-disseny-portada-width/2;
                // top: ($top - $ico-disseny-portada-height) / 2;
            }
        }
        &--feines a {
            background-color: #dcdcdc;
            &:before {
                @extend .ico-feines-home;
                margin-left: -$ico-feines-home-width/2;
                // top: ($top - $ico-feines-home-height) / 2;
            }
        }

        @media #{$medium-up} {
            float: left;
            width: 50%;
        }

        @media #{$large-up} {
            width: 33.333%;

            // elements de color laterals
            &--top-left,
            &--bottom-left {
                &:before {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 1000%;
                    position: absolute;
                    top: 0;
                    left: -1000%;
                }
            }
            &--top-right,
            &--bottom-right,
            &--top-right-es {
                &:after {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 1000%;
                    position: absolute;
                    top: 0;
                    right: -1000%;
                }
            }
            &--top-left:before {
                background-color: darken(#6dc3ee, 15%);
            }
            &--bottom-left:before {
                background-color: darken(#f3b822, 15%);
            }
            &--top-right:after {
                background-color: darken(#95d1e0, 15%);
            }
            &--top-right-es:after {
                background-color: darken(#95d1e0, 15%);
            }
            &--bottom-right:after {
                background-color: darken(#dedede, 15%);
            }
        }

        a {
            text-decoration: none;
            display: block;
            width: 100%;
            height: 100%;
            position: relative;

            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                top: 25px;
            }

            &:after {
                @extend .plec-portada;
                content: "";
                display: block;
                right: 0;
                bottom: -1px;
                position: absolute;

                transition:
                    opacity 0.15s ease-out,
                    height 0.15s ease-out,
                    width 0.15s ease-out;
                opacity: 0;
                height: 0;
                width: 0;
            }

            &:hover,
            &:focus {
                &:after {
                    opacity: 1;
                    height: 38px;
                    width: 46px;
                }
            }

            // @media #{$large-up} {
            //     height: rem-calc(313);
            // }
        }
    }

    &__descr {
        position: absolute;
        top: rem-calc($top);
        width: 100%;

        .grid__item--blog & {
            top: rem-calc(64);
        }

        // Nadala 2016
        .grid__item--catn & {
            top: rem-calc(150);
        }
    }

    &__pre-title {
        text-transform: uppercase;
        font-size: rem-calc(14);
        color: #996514;
        display: block;
        &:before {
            content: "";
            display: inline-block;
            width: 85px;
            height: 1px;
            background-color: #996514;
            vertical-align: middle;
            margin-right: 15px;
        }
        &:after {
            content: "";
            display: inline-block;
            width: 85px;
            height: 1px;
            background-color: #996514;
            vertical-align: middle;
            margin-left: 15px;
        }
    }

    &__title {
        @include bridone;
        font-size: rem-calc(36);
        margin-bottom: 0.25em;
        line-height: 1;
        padding: rem-calc(0 10);
        color: #000;

        // .grid__item--webs & {
        //     color: $white;
        // }
        // .grid__item--intranets & {
        //     color: $white;
        // }
        // .grid__item--cido & {
        //     color: $white;
        // }
        // .grid__item--igtp & {
        //     color: $white;
        // }
        // .grid__item--eines-a-mida & {
        //     color: $white;
        // }
        // .grid__item--cms & {
        //     color: $white;
        // }
        // .grid__item--comunicacio & {
        //     color: $white;
        // }
        // .grid__item--dg & {
        //     color: $white;
        // }
        // .grid__item--illustracio & {
        //     color: $white;
        // }
        // .grid__item--tipografia & {
        //     color: #272727;
        // }
        // .grid__item--infografies & {
        //     color: $white;
        // }
        // .grid__item--apps & {
        //     color: $white;
        // }
        // .grid__item--disseny & {
        //     //color: $white;
        // }
        // .grid__item--feines & {
        //     color: #4a4a4a;
        // }

        // nadala 2015
        .grid__item--catn & {
            font-family: Lato, sans-serif;
            font-weight: 900;
            font-size: rem-calc(45);
            font-style: normal;
            color: $black;
            padding-left: rem-calc(30);
            padding-right: rem-calc(30);
            line-height: 1;
            margin-bottom: 0.25em;
        }

        // feines
        .grid__item--endp & {
            color: #ab6c10;
        }
        .grid__item--orto & {
            color: $white;
        }
        .grid__item--sang & {
            color: $white;
        }
        .grid__item--urv & {
            color: $white;
        }

        // blog
        .grid__item--blog & {
            color: #272727;
        }
    }

    &__text {
        font-size: rem-calc(18);
        margin-bottom: 0;
        padding: rem-calc(0 40);
        line-height: 1.3;
        color: #000;

        // .grid__item--webs & {
        //     color: #41270f;
        // }
        // .grid__item--intranets & {
        //     color: #41270f;
        // }
        // .grid__item--eines-a-mida & {
        //     color: #41270f;
        // }
        // .grid__item--igtp & {
        //     color: #41270f;
        // }
        // .grid__item--cms & {
        //     color: $white;
        // }
        // .grid__item--comunicacio & {
        //     color: #41270f;
        // }
        // .grid__item--dg & {
        //     color: $white;
        // }
        // .grid__item--illustracio & {
        //     color: $white;
        // }
        // .grid__item--tipografia & {
        //     color: #41270f;
        // }
        // .grid__item--infografies & {
        //     color: #41270f;
        // }
        // .grid__item--apps & {
        //     color: #41270f;
        // }
        // .grid__item--disseny & {
        //     color: #41270f;
        // }
        // .grid__item--feines & {
        //     color: #41270f;
        // }

        // nadala 2015
        .grid__item--catn & {
            font-family: Lato, sans-serif;
            font-weight: bold;
            font-size: rem-calc(16);
            font-style: normal;
            color: $black;
        }

        // feines
        .grid__item--endp & {
            color: #41270f;
        }
        .grid__item--orto & {
            color: $black;
        }
        .grid__item--sang & {
            color: $white;
        }
        .grid__item--urv & {
            color: $white;
        }
    }

    .ico-nou {
        position: absolute;
        right: 0;
        top: -23px;
    }
}
