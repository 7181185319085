.button {

    transition: color .1s, background-color .1s;
    .main-footer & {
        padding: rem-calc(12 34) !important;
        font-size: rem-calc(18);
        display: block;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 30px;
        &:hover,
        &:focus {
            color: #000000;
            background-color: $white;
            text-decoration: none;
        } 
    }
    .floor--webs-7 & {
        border-color: $webs;
        color: $webs;
    }

    .floor--eines-a-mida-4 & {
        border-color: $eines-a-mida;
        color: $eines-a-mida;
    }

    .floor--comunicacio-6 & {
        border-color: $comunicacio;
        color: $comunicacio;
    }

    .floor--case-3 & {
        border-color: black;
        color: black;
        margin-top: rem-calc(60);
        margin-bottom: rem-calc(60);
    }

    .cookies & {
        font-size: rem-calc(18) !important;
        border-width: 2px;
        border-color: #f5bc1c;
        padding: rem-calc(12 34) !important;
    }

    // efectes hover
    .comunicacio & {
        &:hover,
        &:focus {
            color: $white;
            background-color: $comunicacio;
        }
    }
    .contact & {
        &:hover,
        &:focus {
            color: black;
            background-color: $white;
        }
    }
    .floor--webs-7 & {
        &:hover,
        &:focus {
            color: $white;
            background-color: $webs;
        }
    }
    .floor--cta & {
        &:hover,
        &:focus {
            background-color: black;
        }
        .home & {
            &:hover,
            &:focus {
                color: #4a4a4a;
            }
        }
        .webs &, .treballa & {
            &:hover,
            &:focus {
                color: $webs;
            }
        }
        .que & {
            &:hover,
            &:focus {
                color: #65ccd8;
            }
        }
        .intranets & {
            &:hover,
            &:focus {
                color: $intranets;
            }
        }
        .cms & {
            &:hover,
            &:focus {
                color: $cms;
            }
        }
        .eines-a-mida & {
            &:hover,
            &:focus {
                color: $eines-a-mida;
            }
        }
        .comunicacio & {
            &:hover,
            &:focus {
                color: $comunicacio;
            }
        }
        .dg & {
            &:hover,
            &:focus {
                color: $dg;
            }
        }
        .illustracio & {
            &:hover,
            &:focus {
                color: $illustracio;
            }
        }
        .tipo & {
            &:hover,
            &:focus {
                color: $tipografia;
            }
        }
        .infografies & {
            &:hover,
            &:focus {
                color: $infografies;
            }
        }
    }

    // feines
    .floor--case-1 & {
        &:hover,
        &:focus {
            background-color: $white;
            color: #90282a;
        }
    }
    .floor--case-2 & {
        &:hover,
        &:focus {
            background-color: #4a4a4a;
            color: #f3f1d6;
        }
    }
    .floor--case-3 & {
        &:hover,
        &:focus {
            background-color: $white;
            color: #971e37;
        }
    }
    .floor--case-6 & {
        &:hover,
        &:focus {
            background-color: $white;
            color: #4A90E2;
        }
    }

    @media #{$medium-up} {
        padding: rem-calc(22 65 23);
    }

    // botó petit
    &.small {
        @include demibold;
        font-size: rem-calc(18);
        line-height: 1.2;
        padding-left: rem-calc(25);
        padding-right: rem-calc(25);
        padding: rem-calc(11 40 8);

        @media #{$medium-up} {
            font-size: rem-calc(26);
            border-width: 3px;
        }
    }

    // botó gran
    &.large {
        @include demibold;
        font-size: rem-calc(18);
        line-height: 1.2;
        padding: rem-calc(13 20 14);

        @media #{$medium-up} {
            font-size: rem-calc(31);
            border-width: 3px;
        }
    }

    // botó més gran
    &.larger {
        @include demibold;
        font-size: rem-calc(22);
        line-height: 1.2;
        padding: rem-calc(23 40 22);

        @media #{$medium-up} {
            font-size: rem-calc(31);
            border-width: 3px;
            padding: rem-calc(23 60 22);
        }
    }
}

//modificacions form dins del main
.main-content { 
    form {
        button, .button {
            border: 2px #000000 solid!important;
            color: #000000;
            transition: color .1s, background-color .1s;
            margin-top: rem-calc(25);
            &:hover,
            &:focus {
                background-color: #000000;
                color: $white;
                border-color: $white;
            }
        }
        textarea {
            border: 1px #cccccc solid
        }
        label[for=protecciodades] {
            color: #4d4d4d;
            a {
                color: #e71481!important;
            }
        }
        .text-form {
            p {
                font-size: rem-calc(14);
            }
        }
    }
}