// estils grid (https://github.com/codrops/ThumbnailGridExpandingPreview)

.og-grid {
    list-style: none;
    // padding: 20px 0;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    font-size: 0;
}

.og-grid li {
    display: inline-block;
    // margin: 10px 5px 0 5px;
    vertical-align: top;
    // height: 250px;
}

.og-grid li > a,
.og-grid li > a img, .og-grid li > span, .og-grid li > span img  {
    // border: none;
    outline: none;
    display: block;
    position: relative;
}


// .og-grid li.og-expanded > a::after {
//     top: auto;
//     border: solid transparent;
//     content: " ";
//     height: 0;
//     width: 0;
//     position: absolute;
//     pointer-events: none;
//     border-bottom-color: #4b7373;
//     border-width: 15px;
//     left: 50%;
//     margin: -19px 0 0 -15px;
// }

.og-expander {
    position: absolute;
    background: $white;
    top: auto;
    left: 0;
    width: 100%;
    margin-top: $paragraph-margin-bottom;
    text-align: left;
    height: 0;
    overflow: hidden;
        height: auto !important;

    // &:before,
    // &:after {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     background: #66ccd8;
    //     width: 50%;
    //     height: 1px;
    //     left: 0;
    // }
    // &:before {
    //     top: 0;
    // }
    // &:after {
    //     bottom: 0;
    // }
}

.og-expander-inner {
    // padding: 50px 30px;
    height: 100%;
}

.og-close {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    @media #{$medium-only} {
        top: 140px !important;
    }
    @media #{$medium-up} {
        top: 95px;
    }
}

.og-close::before,
.og-close::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    height: 1px;
    background: black;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

.og-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.og-close:hover::before,
.og-close:hover::after {
    background: $darker-gray;
}

.og-fullimg,
.og-details {
    width: 50%;
    // float: left;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.og-details {
    // padding: 0 40px 0 20px;
    padding: rem-calc(100) $column-gutter $paragraph-margin-bottom;

    @media #{$medium-only} {
        padding-top: rem-calc(160);
    }

    .img-left & {
        float: right;
    }
    .img-right & {
        float: left;
    }
}

.og-fullimg {
    padding: rem-calc(80) $column-gutter 0;

    @media #{$medium-only} {
        padding-top: rem-calc(200) !important;
    }

    @media #{$medium-up} {
        padding-top: rem-calc(100);
    }

    .img-left & {
        float: left;
    }
    .img-right & {
        float: right;
    }
}

.og-fullimg img {
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
}

.og-details h3 {
    // font-weight: 300;
    // font-size: 52px;
    // padding: 40px 0 10px;
    // margin-bottom: 10px;
}

// .og-details p {
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 22px;
//     color: #999;
// }

// .og-details a {
//     font-weight: 700;
//     font-size: 16px;
//     color: #333;
//     text-transform: uppercase;
//     letter-spacing: 2px;
//     padding: 10px 20px;
//     border: 3px solid #333;
//     display: inline-block;
//     margin: 30px 0 0;
//     outline: none;
// }

// .og-details a::before {
//     content: '\2192';
//     display: inline-block;
//     margin-right: 10px;
// }

// .og-details a:hover {
//     border-color: #999;
//     color: #999;
// }

.og-loading {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ddd;
    box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ccc;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    -webkit-animation: loader 0.5s infinite ease-in-out both;
    -moz-animation: loader 0.5s infinite ease-in-out both;
    animation: loader 0.5s infinite ease-in-out both;
}

@-webkit-keyframes loader {
    0% { background: #ddd; }
    33% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd; }
    66% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc; }
}

@-moz-keyframes loader {
    0% { background: #ddd; }
    33% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd; }
    66% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc; }
}

@keyframes loader {
    0% { background: #ddd; }
    33% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ccc, -15px 30px 1px #ddd; }
    66% { background: #ccc; box-shadow: 0 0 1px #ccc, 15px 30px 1px #ddd, -15px 30px 1px #ccc; }
}

// @media screen and (max-width: 830px) {

//     .og-expander h3 { font-size: 32px; }
//     .og-expander p { font-size: 13px; }
//     .og-expander a { font-size: 12px; }

// }

@media screen and (max-width: 650px) {

    .og-fullimg { display: none; }
    .og-details { float: none; width: 100%; }

}



// estils propis
.team {
    font-size: 0;

    &__container {
        position: static;
    }

    &__member {
        height: auto;
        width: rem-calc(300);
        margin: 0 0 rem-calc(60);

        transform: none !important; /*perquè funcioni bé l'scrollreveal*/

        .sr & {
            visibility: hidden;
        }

        @media #{$medium-up} {
            margin-left: $column-gutter/2;
            margin-right: $column-gutter/2;
        }

        @media #{$large-up} {
            &--1,
            &--4,
            &--7,
            &--10 {
                margin-left: 0;
            }
            &--3,
            &--6,
            &--9,
            &--12 {
                margin-right: 0;
            }
        }

        > a, > span {
            text-decoration: none;
            border: 1px solid transparent;
            border-radius: 27px;
            transition: border-color .1s ease-in;
            padding: rem-calc(10 24);

            &:focus,
            &:hover {
                border-color: #66ccd8;
            }

            @media only screen and (min-width: em-calc(660)) {
                height: rem-calc(400);
                padding: 0;
            }
        }
    }

    &__pict {
        margin: $paragraph-margin-bottom auto;

        @media only screen and (min-width: em-calc(660)) {
            margin: 0;
            position: absolute !important;
            top: 25px;
            left: 50%;

            .team__member--1 & {
                margin-left: -255/2 + px;
            }
            .team__member--2 & {
                margin-left: -250/2 + px;
            }
            .team__member--3 & {
                margin-left: -208/2 + px;
            }
            .team__member--4 & {
                margin-left: -220/2 + px;
            }
            .team__member--5 & {
                margin-left: -250/2 + px;
            }
            .team__member--6 & {
                margin-left: -282/2 + px;
            }
            .team__member--7 & {
                margin-left: -262/2 + px;
            }
            .team__member--8 & {
                margin-left: -235/2 + px;
            }
            .team__member--9 & {
                margin-left: -265/2 + px;
            }
            .team__member--10 & {
                margin-left: -284/2 + px;
            }
            .team__member--11 & {
                margin-left: -184/2 + px;
            }
            .team__member--12 & {
                margin-left: -230/2 + px;
            }
        }
    }

    &__meta {
        @media only screen and (min-width: em-calc(660)) {
            position: absolute !important;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }

    &__name {
        @include light;
        font-size: rem-calc(22);
        color: #000;
        margin-bottom: 0;
        text-align: center;
        @media #{$medium-up} {
            text-align: left;
        }
        margin-top: rem-calc(10);
        span {
            &:first-child {
                @include demibold;
                margin-right: rem-calc(10);
            }
        }
    }

    &__role {
        font-size: rem-calc(13);
        margin: 0 0 $paragraph-margin-bottom;
        color: #000;
    }
}

.og-expanded {
    > a {
        border-color: #66ccd8;
    }
}

.og-expander-inner {
    @include grid-row;
    padding: 0 10px;
}

// estils info desplegada
.og-details {
    &__name {
        line-height: 1.2;
        margin: 0;
    }
    &__firstname {
        @include bridone;
        color: #e71481;
        font-size: rem-calc(65);
        display: block;
        text-indent: 2px; // per evitar que la inicial quedi tallada
    }
    &__surname {
        @include bridone;
        color: #66ccd8;
        font-size: rem-calc(36);
        display: block;
        text-indent: 2px; // per evitar que la inicial quedi tallada
        position: relative;
        top: -.5em;
    }
    &__role {
        @include demibold;
        color: black;
        font-size: rem-calc(14);
        margin-top: rem-calc(-20);
    }
    &__description {
        color: black;
        font-size: rem-calc(14);
        @media #{$medium-up} {
            column-count: 2;
        }
    }
    &__social {
        list-style: none;
        font-size: rem-calc(13);
        color: $darker-gray;
        margin-left: 0;

        // provisional
        // display: none;

        &__item {
            display: block;
            padding-left: 25px;
            position: relative;
            margin-bottom: rem-calc(10);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                display: block;
            }

            &--email:before {
                @extend .ico-email-small;
                margin-top: -$ico-email-small-height/2;
            }
            &--twitter {
                &:before {
                    @extend .ico-twitter-small;
                    margin-top: -$ico-twitter-small-height/2;
                }
            }
            &--instagram {
                &:before {
                    @extend .ico-instagram-small;
                    margin-top: -$ico-instagram-small-height/2;
                }
            }
            &--linkedin {
                &:before {
                    @extend .ico-linkedin-small;
                    margin-top: -$ico-linkedin-small-height/2;
                }
            }

            @media #{$medium-up} {
                float: left;
                width: 50%;
                &:nth-child(odd) {
                    width: 48%;
                    margin-right: 2%;
                }
                &:nth-child(even) {
                    width: 48%;
                    margin-left: 2%;
                }
            }

            a {
                color: #e71481 !important;
            }
        }

        a {
            @include hover;
            color: $darker-gray;
        }
    }
}
.team {
    text-align: center;
    @media #{$medium-up} {
        text-align: left;
    }
    li {
        .caixa-img {
            @media #{$medium-up} {
                display: flex;
                flex-flow: column wrap;
                align-items: left;
            }
            margin-top: rem-calc(20);
            .box{
                @media #{$medium-up} {
                    width: rem-calc(177);
                    height: rem-calc(170);
                    overflow: hidden;
                }
                img{
                    width: 100%;
                    height: auto;
                  }
            }

        }
        .team__text {
            min-height: rem-calc(120);
            position: relative;
            .team-xarxes {
                @media #{$medium-up} {
                    position: absolute;
                    bottom: 0;
                    border-bottom: 1px solid #d1d1d1;
                    padding-bottom: rem-calc(8);
                    width: 100%;
                    margin-left: 0;
                }

                li {
                    display: inline-block;
                    margin-right: rem-calc(17);
                    svg {
                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }
        }


    }
}
