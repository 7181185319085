.top {
    display: none;
    width: rem-calc(90);
    height: rem-calc(90);
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: $black;
    background-color: rgba(0,0,0,.4);
    border-radius: 10px;
    text-align: center;
    transition: background-color .2s ease-in;
    z-index: 10;

    &__img {
        @extend .ancora-pujar;
        display: inline-block;
        margin-top: 13px;

        @media #{$medium-up} {
            margin-top: 28px;
        }
    }

    &:hover {
        background-color: rgba(0,0,0,.8);
    }

    @media #{$medium-up} {
        width: rem-calc(120);
        height: rem-calc(120);
        right: 40px;
        bottom: 40px;
    }
}
