section.contact, section.contact-ok, section.contact-ko {
    padding: rem-calc(40 0);
    text-align: center;
    background: black;
    position: relative;
    box-shadow: 0 25px 200px -75px rgba(0,0,0,.8);
    color: $white;

    .js & {
        display: none;
    }

    &.is-visible {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 75px;

        .home & {
            top: 0;
            @media #{$medium-up} {
                top: 113px;
            }
        }

        @media #{$medium-only} {
            top: 120px;
        }
    }
}

.contact__container {
    position: static;
}
.contact__data {
    text-align: left;
    text-align: center;
    @media #{$medium-up} {
        text-align: left;
        margin-bottom: rem-calc(55) !important;
    }
}
.contact__wrap, .contact__wrap-ok {
    border-bottom: 1px solid #979797;
    margin-bottom: $paragraph-margin-bottom;
    @media #{$medium-up} {
        border-bottom: 0;
        margin-bottom: 0;
    }
}
.contact__wrap-ok {
    min-height: rem-calc(300);
    img {
        margin-bottom: rem-calc(30);
    }
    a {
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(50);
    }
}
.contact__txt {
    font-size: rem-calc(16);
    margin-bottom: 0;

    .contact-form & {
        margin-bottom: $paragraph-margin-bottom;
    }
}
.contact__tel {
    font-size: rem-calc(29);
    color: #fbffd4;
    line-height: 1.2;
    @media #{$medium-only} {
        font-size: rem-calc(25);
    }
}
.contact__adr {
    font-size: rem-calc(16);
    color: #fbffd4;
    line-height: 1.2;
    @media #{$medium-up} {
        font-size: rem-calc(22);
    }
}
form {
    label {
        &.error {
            @include form-error-message;
        }
    }
    input,
    textarea {
        padding: .5em 1em;
        width: 100%;
        color: black;
        margin-bottom: $paragraph-margin-bottom;
        background-image: linear-gradient(to bottom, #d8d8d8, #ffffff);
        border: 0;
        border-radius: 0;

        &.error {
            margin-bottom: 0;
            border: 1px solid $alert-color;
        }
    }
    textarea {
        height: rem-calc(119)!important;
    }
    .button {
        font-size: 1.625rem;
        border-width: 3px;
        padding: .6875rem 2.5rem .5rem;
        font-weight: 600;
        line-height: 1.2;
        font-style: normal;
        border-radius: 1000px;

        }
}

// contact 2 - (s'obre al final de cada apartat)
.contact-2 {
    padding-top: rem-calc(55);

    .js & {
        display: none;
    }

    &.is-visible {
        // display: block;
    }
}

// protecció de dades
label[for="protecciodades"] {
    color: $white;
    text-align: left;
    margin-bottom: $paragraph-margin-bottom;

    a {
        color: $white;
    }
}
.desit {
    label {
    color: $white;
    text-align: left;
    margin-bottom: $paragraph-margin-bottom;

    a {
        color: $white;
    }
    }

}
#protecciodades, input[type="checkbox"] {
    border: 1px solid $gray;
    height: rem-calc(25);
    width: rem-calc(25);
    margin: 0 .5em 0 0;
    appearance: none;
    position: relative;
    vertical-align: middle;

    &:checked {
      &:after {
        content: url('img/checkbox.png');
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -7px 0 0 -9.5px;
      }
    }
}
.senselabel {
    label {
        display: none;
    }
}
.form-body {
    small {
        display: none;
    }
    a {
        color: #efbf3f;
    }
}
.treballa-nos {
    label {
        display: none;

    }
    label[for="protecciodades"] {
        display: block;
    }
    input,
    textarea {
        padding: .5em 1em;
        width: 100%;
        color: black;
        margin-bottom: $paragraph-margin-bottom;
        background-color: #ffffff;
        background-image: none;
        border: 0;
        border-radius: 0;

        &.error {
            margin-bottom: 0;
            border: 1px solid $alert-color;
        }
    }
    textarea {
        height: rem-calc(266)!important;
    }
    form .button {
        font-size: 1.625rem;
        border-width: 3px;
        padding: .6875rem 2.5rem .5rem;
        font-weight: 600;
        line-height: 1.2;
        font-style: normal;
        border-radius: 1000px;
        &:hover {
            background-color: #fff;
            color: #24bdf2;
        }
        }
}