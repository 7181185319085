.utils-infografies {
    list-style: none;
    margin-left: -$column-gutter/2;
    margin-right: -$column-gutter/2;
    text-align: center;
    font-size: 0;
}
.utils-infografies__item {
    margin-bottom: rem-calc(40);
    display: inline-block;
    width: 100%;
    padding: 0 $column-gutter/2;
    font-size: rem-calc(18);
    line-height: 1.3;
    vertical-align: top;

    @media #{$medium-up} {
        width: 33.333%;
    }

    @media #{$large-up} {
        width: 25%;
    }

    &:before {
        content: '';
        display: block;
        margin: 0 auto rem-calc($icon-margin-bottom);
    }
    &--1 {
        &:before {
            @extend .ico-infografia-explicar;
            margin-top: $ico-infografia-difusio-height - $ico-infografia-explicar-height;
        }
    }
    &--2 {
        &:before {
            @extend .ico-infografia-usuari;
            margin-top: $ico-infografia-difusio-height - $ico-infografia-usuari-height;
        }
    }
    &--3 {
        &:before {
            @extend .ico-infografia-complexos;
        }
    }
    &--4 {
        &:before {
            @extend .ico-infografia-difusio;
        }
    }
    &--5 {
        &:before {
            @extend .ico-infografia-atencio;
            margin-top: $ico-infografia-xxss-height - $ico-infografia-atencio-height;
        }
    }
    &--6 {
        &:before {
            @extend .ico-infografia-xxss;
        }
    }
}
