.social-list {
    margin: rem-calc(30) 0 0;
    list-style: none;
    font-size: 0;
    text-align: center;
}
.social-list__item {
    display: inline;
    a {
        margin: 0 rem-calc(10);
        display: inline-block;
        vertical-align: middle;
    }

    &--fb {
        a {
            @extend .ico-xxss-facebook;
            &:hover {
                @extend .ico-xxss-facebook-hover;
            }
        }
    }
    &--twitter {
        a {
            @extend .ico-xxss-twitter;
            &:hover {
                @extend .ico-xxss-twitter-hover;
            }
        }
    }
    &--instagram {
        a {
            @extend .ico-xxss-instagram;
            &:hover {
                @extend .ico-xxss-instagram-hover;
            }
        }
    }
}
