// layout
.main-footer {
    background: black;
    text-align: center;
    padding: rem-calc(75 0 50);

    .base & {
        margin-top: rem-calc(110);
    }
}
.logo-footer {
    display: block;
    margin: 0 auto;
    margin-bottom: rem-calc(57);
    //@extend .antaviana-peu20;
    @extend .logo-antaviana;
}
.contact-list {
    display: inline-block;
    font-size: rem-calc(16);
    list-style: none;
    margin: 0 auto;
    margin-bottom: rem-calc(10);
    @media #{$medium-up} {
        text-align: left;
    }
}
.contact-list__item {
    color: #dbdbdb;
    margin-bottom: rem-calc(25);

    &:before {
        content: '';
        display: block;
        margin: 0 auto 10px;

        @media #{$medium-up} {
            position: absolute;
            left: 0;
            top: 50%;
        }
    }

    &--phone {
        &:before {
            @extend .ico-telefon;
            @media #{$medium-up} {
                margin-top: -$ico-telefon-height/2;
                margin-left: 2.5px;
            }
        }
    }

    &--mail {
        &:before {
            @extend .ico-email;
            @media #{$medium-up} {
                margin-top: -$ico-email-height/2;
            }
        }
    }

    &--tweet {
        &:before {
            @extend .ico-twitter;
            @media #{$medium-up} {
                margin-top: -$ico-twitter-height/2;
            }
        }
    }

    &--visit {
        a {
            display: inline !important;
        }
        &:before {
            @extend .ico-lloc;
            @media #{$medium-up} {
                margin-top: -$ico-lloc-height/2;
                margin-left: 1px;
            }
        }
    }

    a {
        color: $ft-link;
    }

    a,span {
        display: block;

        @media #{$medium-up} {
            display: inline;
        }
    }

    @media #{$medium-up} {
        position: relative;
        padding-left: 30px;
        margin-bottom: .8em;
    }
}
// .address {
//     font-size: rem-calc(12);
//     color: $white;
//     margin: 0 0 .5em;

//     a {
//         color: $ft-link;
//     }

//     @media #{$large-up} {
//         margin: 0;
//         float: left;
//     }
// }
// .address__txt {
//     display: block;

//     @media #{$medium-up} {
//         display: inline;
//     }
// }
// .address__dot {
//     display: none;

//     @media #{$medium-up} {
//         display: inline;
//     }
// }
.legal {
    font-size: rem-calc(12);
    color: $white;
    margin: 0 0 .5em;
    @media #{$medium-up} {
        display: inline;
        padding-right: rem-calc(10);
    }
}
.legal-list {
    font-size: rem-calc(12);
    color: $white;
    margin: 0;
    @media #{$medium-up} {
        display: inline;
        padding-left: rem-calc(10);
    }

    // @media #{$large-up} {
    //     // float: right;
    // }
}
.legal-list__item {
    display: inline;

    &:after {
        content: '\2010';
    }

    &:last-child:after {
        content: '';
    }

    a {
        color: $ft-link;
    }
}

