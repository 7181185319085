.testimonis__title {
    @include demibold;
    font-size: rem-calc(35);
    border: 3px solid;
    padding: rem-calc(10 30 7);
    display: inline-block;
    margin-bottom: rem-calc(95);
    position: relative;
    color: #4a4a4a;

    @media #{$medium-up} {
        padding-left: rem-calc(100);
        padding-right: rem-calc(100);
    }

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
    }

    .webs & {
        border-color: $webs;
        &:before {
            @extend .testimoni-e-webs;
            margin-top: -$testimoni-e-webs-height/2;
            left: -$testimoni-e-webs-width;
        }
        &:after {
            @extend .testimoni-d-webs;
            margin-top: -$testimoni-d-webs-height/2;
            right: -$testimoni-e-webs-width;
        }
    }

    .intranets & {
        border-color: $intranets;
        &:before {
            @extend .testimoni-e-intranets;
            margin-top: -$testimoni-e-intranets-height/2;
            left: -$testimoni-e-intranets-width;
        }
        &:after {
            @extend .testimoni-d-intranets;
            margin-top: -$testimoni-d-intranets-height/2;
            right: -$testimoni-e-intranets-width;
        }
    }

    .eines-a-mida & {
        border-color: $eines-a-mida;
        &:before {
            @extend .testimoni-e-eines;
            margin-top: -$testimoni-e-eines-height/2;
            left: -$testimoni-e-eines-width;
        }
        &:after {
            @extend .testimoni-d-eines;
            margin-top: -$testimoni-d-eines-height/2;
            right: -$testimoni-e-eines-width;
        }
    }

    .cms & {
        border-color: $cms;
        &:before {
            @extend .testimoni-e-gestor;
            margin-top: -$testimoni-e-gestor-height/2;
            left: -$testimoni-e-gestor-width;
        }
        &:after {
            @extend .testimoni-d-gestor;
            margin-top: -$testimoni-d-gestor-height/2;
            right: -$testimoni-e-gestor-width;
        }
    }

    .infografies & {
        border-color: $infografies;
        &:before {
            @extend .testimoni-e-infografia;
            margin-top: -$testimoni-e-infografia-height/2;
            left: -$testimoni-e-infografia-width;
        }
        &:after {
            @extend .testimoni-d-infografia;
            margin-top: -$testimoni-d-infografia-height/2;
            right: -$testimoni-e-infografia-width;
        }
    }
}

.testimonis__quote {
    text-align: left;
}
.testimonis__cite {
    &:before {
        content: '';
    }
}
.testimonis__pict {
    margin-bottom: $paragraph-margin-bottom;
    @media #{$medium-up} {
        margin-bottom: 0;
    }
}
.testimonis__name {
    @include light;
    font-size: rem-calc(30);
    line-height: 1.2;
    display: block;
    margin-bottom: .5em;
    text-align: center;

    @media #{$medium-up} {
        text-align: left;
    }
}
.testimonis__role {
    @include demibold;
    font-size: rem-calc(20);
    display: block;
    line-height: 1.2;
    text-align: center;

    @media #{$medium-up} {
        text-align: left;
    }
}
.testimonis__text {
    @include light;
    font-size: rem-calc(18);
    line-height: 1.3;
    padding-left: 55px;
    position: relative;
    display: block;

    @media #{$medium-up} {
        font-size: rem-calc(29);
    }

    &:before {
        content: '\201C';
        font-size: rem-calc(125);
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        line-height: .9;

        .webs & {
            color: $webs;
        }

        .intranets & {
            color: $intranets;
        }

        .eines-a-mida & {
            color: $eines-a-mida;
        }

        .cms & {
            color: $cms;
        }

        .infografies & {
            color: $infografies;
        }
    }

    &:after {
        content: '\201D';
    }
}
