// inline links
// .main-content a:not(.button) {
//     text-decoration: none;
//     text-shadow: 0.05em 0 0 #fff, -0.05em 0 0 #fff,
//         0 0.05em 0 #fff, 0 -0.05em 0 #fff,
//         0.1em 0 0 #fff, -0.1em 0 0 #fff,
//         0 0.1em 0 #fff, 0 -0.1em 0 #fff;
//     background-image: linear-gradient(to right, #000 0%, #000 100%);
//     background-repeat: repeat-x;
//     background-position: bottom 0.05em center;
//     background-size: 100% 0.05em;
// }
a {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    .main-nav &,
    .main-footer & {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}


// tel link
a[href^=tel] {
    text-decoration:inherit;
    color: inherit;
}

b, strong {
    @include demibold;
}

// i, em {
//     @include italic;
// }

// headings
h1 {
    @include light;
    font-size: rem-calc(32);
    margin-bottom: $paragraph-margin-bottom*3;
    line-height: 1.2;
    color: #4a4a4a;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-align: center;

    @media #{$medium-up} {
        font-size: rem-calc(60);
        letter-spacing: 11px;
        margin-bottom: rem-calc(90);
        .feines-fitxa & {
            margin-bottom: rem-calc(26);
        }
    }
}
h2 {
    @include light;
    font-size: rem-calc(24);
    margin-bottom: $paragraph-margin-bottom;
    line-height: 1.2;
    color: #3a99ac;
    text-align: center;

    @media #{$medium-up} {
        font-size: rem-calc(29);
    }
}
.section-title, p.section-title {
    @include bridone;
    font-size: rem-calc(40) !important;
    line-height: .9;
    text-transform: lowercase;
    color: black;
    margin-bottom: $paragraph-margin-bottom;
    letter-spacing: 0;

    &:before {
        content: '';
        display: block;
        margin: rem-calc(30) auto rem-calc(40);
        // transition: top .4s ease-out, opacity .4s ease-out;
        // position: relative;
        // top: 0;
        // opacity: 1;

        @media #{$medium-up} {
            margin-top: 0;
        }
    }

    // .js-out & {
    //     &:before {
    //         position: relative;
    //         top: rem-calc(-400);
    //         opacity: 0;
    //     }
    // }

    @media #{$medium-up} {
        font-size: rem-calc(86);
        .feines-fitxa & {
            font-size: rem-calc(62) !important;
        }
        .feines & {
            font-size: rem-calc(62) !important;
        }
    }

    &--who {
        &:before {
            @extend .ico-somantaviana;
        }
    }
    &--work {
        &:before {
            @extend .ico-webs;
        }
    }
    &--health {
        &:before {
            @extend .ico-cor;
        }
    }
    &--recerca {
        &:before {
            @extend .ico-recerca;
        }
    }
    &--labs {
        &:before {
            @extend .ico-labs;
        }
    }
    &--contact {
        color: $white;
        text-transform: lowercase;
    }
    &--2 {
        @include light;
        font-size: rem-calc(30);
        line-height: 1.2;
        text-transform: uppercase;
        /*color: $white;*/
        letter-spacing: 10px;

        span {
            text-transform: none;
        }

        &:before {
            margin-bottom: rem-calc(87);
        }

        @media #{$medium-up} {
            font-size: rem-calc(60);
        }
    }
    //&--webs {
        //&:before {
            //@extend .ico-quefem-webs;
            //margin: rem-calc(-$ico-quefem-webs-height/2) auto rem-calc(63);
        //}
    //}
    //&--intranets {
        //&:before {
            //@extend .ico-quefem-intranets;
            //margin: rem-calc(-$ico-quefem-intranets-height/2) auto rem-calc(40);
        //}
    //}
    //&--eines-a-mida {
        //&:before {
            //@extend .ico-quefem-eines;
            //margin: rem-calc(-$ico-quefem-eines-height/2) auto rem-calc(40);
        //}
    //}
    //&--cms {
        //&:before {
            //@extend .ico-quefem-gestors;
            //margin: rem-calc(-$ico-quefem-gestors-height/2) auto rem-calc(40);
        //}
    //}
    //&--dg {
       // &:before {
            //@extend .ico-quefem-disseny;
            //margin: rem-calc(-$ico-quefem-disseny-height/2) auto rem-calc(40);
       // }
    //}
    //&--il {
       // &:before {
            //@extend .ico-quefem-illustracio;
            //margin: rem-calc(-$ico-quefem-illustracio-height/2) auto rem-calc(40);
        //}
    //}
    &--app {
        &:before {
            @extend .ico-quefem-apps;
            margin: rem-calc(-$ico-quefem-apps-height/2) auto rem-calc(40);
        }
    }
    &--tipo {
        &:before {
            @extend .ico-tipografia;
            margin: rem-calc(-$ico-tipografia-height/2) auto rem-calc(40);
        }
    }
    //&--comunicacio {
        //&:before {
            //@extend .ico-quefem-comunicacio;
            //margin: rem-calc(-$ico-quefem-comunicacio-height/2) auto rem-calc(63);
        //}
    //}
    &--infografies {
        &:before {
            @extend .ico-quefem-infografies;
            margin: rem-calc(-$ico-quefem-infografies-height/2) auto rem-calc(63);
        }
    }
}
.section-subtitle {
    @include light;
    font-size: rem-calc(19);
    line-height: 1.4;
    color: black;
    margin-bottom: $paragraph-margin-bottom*2;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    .floor--view & {
        /*color: $white;*/
    }
    .com-treballem &,
    .illustracio & {
        color: $darker-gray;
    }

    @media #{$medium-up} {
        font-size: rem-calc(29);
        //width: 65%;
        .feines & {
            font-size: rem-calc(24);
        }
        .cms & {
            width: 100%;
        }
    }
}
.h-a {
    @include demibold;
    font-size: rem-calc(20);
    color: #4a4a4a;
    span:not([lang]) {
        display: block;
    }

    .floor--il-7 & {
        text-align: left;
    }
}
.h-b {
    @include light;
    font-size: rem-calc(32);
    color: $white;
    margin-bottom: rem-calc(70);

    &--icon {
        &:before {
            content: '';
            display: block;
            margin: 0 auto;
            margin-bottom: rem-calc(48);
            @extend .ico-somantaviana-capacitats;
        }
    }

    .floor--case-5 & {
        color: #4a4a4a;
    }
}
.h-b-health {
    @include light;
    font-size: rem-calc(22);
    color: $white;
    margin-bottom: rem-calc(70);
    @media #{$medium-up} {
        font-size: rem-calc(29);
    }
    &--icon {
        &:before {
            content: '';
            display: block;
            margin: 0 auto;
            margin-bottom: rem-calc(34);
            @extend .ico-eines-salut;
        }
    }

    .floor--case-5 & {
        color: #4a4a4a;
        margin: 0 auto;
        max-width: rem-calc(575);
        padding-bottom: rem-calc(70);
    }
}
.h-b-recerca {
    @include light;
    font-size: rem-calc(29);
    color: $white;
    margin-bottom: rem-calc(30);

    &--icon {
        &:before {
            content: '';
            display: block;
            margin: 0 auto;
            margin-bottom: rem-calc(34);
            @extend .logo-fundanet;
        }
    }

    .floor--case-5 & {
        color: #4a4a4a;
        margin: 0 auto;
        max-width: rem-calc(575);
        padding-bottom: rem-calc(70);
    }
}
.h-c {
    @include light;
    font-size: rem-calc(26);
    line-height: 1.2;
    margin-bottom: rem-calc(70);
    color: #4a4a4a;

    @media #{$medium-up} {
        font-size: rem-calc(32);
    }

    &--icon {
        margin-bottom: 0;
        &:before {
            content: '';
            display: block;
            margin: 0 auto rem-calc($icon-margin-bottom);
        }
    }
    &--cms {
        margin-bottom: rem-calc(20);
        &:before {
            @extend .ico-gestors;
        }
    }
    &--intranets {
        margin-bottom: rem-calc(20);
        &:before {
            @extend .ico-intranets;
        }
    }
    &--marca {
        margin-bottom: rem-calc(20);
        &:before {
            @extend .ico-comunicacio-marca;
        }
    }
    .floor--intranets-3 & {
        margin-bottom: $paragraph-margin-bottom;
        @media #{$medium-up} {
            margin-bottom: rem-calc(70);
        }
    }
    .floor--dg-3 & {
        margin-bottom: 0;
    }
}
.h-d {
    @include demibold;
    font-size: rem-calc(31);
    margin-bottom: rem-calc(55);
    line-height: 1.2;
    color: black;
}
.h-d-health {
    @include demibold;
    font-size: rem-calc(18);
    margin: 0 auto;
    margin-bottom: rem-calc(55);
    line-height: 26px;
    color: #4a4a4a;
    width: 75%;
    span {
        @include light;
    }
}
.h-d-labs {
    @include demibold;
    font-size: rem-calc(22);
    margin: 0 auto;
    margin-bottom: rem-calc(60);
    margin-top: rem-calc(100);
    line-height: 26px;
    color: #4a4a4a;
    width: 75%;
    span {
        @include light;
    }
}
.h-d-health-p {
    @include light;
    font-size: rem-calc(22);
    margin-bottom: rem-calc(45);
    line-height: 35px;
    color: #000000;
    @media #{$medium-up} {
        font-size: rem-calc(29);
    }
}
.h-t {
    color: black;
    font-size: rem-calc(32);
}
.h-recerca {
    @include book;
    font-size: rem-calc(22);
    color: #4a4a4a;
    @media #{$medium-up} {
        font-size: rem-calc(29);
        text-align: left;
        width: 30%;
    }
    span {
        display: block;
        font-size: rem-calc(24);

    }
}
.p-recerca {
    @include book;
    font-size: rem-calc(18);
    line-height: rem-calc(26);
    color: #4a4a4a;
    @media #{$medium-up} {
        text-align: left;
    }
}
.p-recerca-b {
    @include book;
    text-align: center;
    font-size: rem-calc(18);
    line-height: rem-calc(26);
    color: $white;
    margin-bottom: rem-calc(20);
    @media #{$medium-up} {
        width: rem-calc(526);
        margin: 0 auto;
        margin-bottom: rem-calc(20);
    }
}
.p-recerca-b {
    p {
        @include book;
        text-align: center;
        font-size: rem-calc(18);
        line-height: rem-calc(26);
        color: $white;
        margin-bottom: rem-calc(20);
        @media #{$medium-up} {
            width: rem-calc(526);
            margin: 0 auto;
            margin-bottom: rem-calc(20);
        }
    }
}
.marge {
    margin-top: rem-calc(70);
}
.h-e {
    @include light;
    font-size: rem-calc(40);
    margin-bottom: rem-calc(40);
    line-height: 1.2;
    color: #4a4a4a;

    @media #{$medium-up} {
        font-size: rem-calc(52);
    }
    &--icon {
        &:after {
            content: '';
            display: block;
            margin: 0 auto;
            margin-top: rem-calc(40);
            @extend .ico-webs-caixa-eines;
        }
    }
}
.h-f {
    font-size: rem-calc(26);
    @include heavy;
    margin-bottom: rem-calc(40);
    line-height: 1.2;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 4.8px;
    .home-video & {
        position: absolute;
        z-index: 1;
        @media #{$small-up} {
            bottom: 150px;
        }
        @media #{$medium-up} {
            bottom: 150px;
            font-size: rem-calc(26);
        }
        @media #{$large-up} {
            bottom: 250px;
            font-size: rem-calc(46);
        }
        @media #{$xlarge-up} {
            bottom: 250px;
        }
    }
    @media #{$medium-up} {
        font-size: rem-calc(52);
        letter-spacing: 9.5px;
    }

    @media #{$xlarge-up} {
        margin-bottom: rem-calc(100);
    }
}
.apartat {
    @include bridone;
    display: block;
    font-size: rem-calc(22);
    text-transform: lowercase;
    color: black;
    margin-bottom: rem-calc(40);

    @media #{$medium-up} {
        font-size: rem-calc(50);
    }
}

// paragraphs
p {
    font-size: rem-calc(15);

    @media #{$medium-up} {
        font-size: rem-calc(16);
    }

    @media #{$large-up} {
        font-size: rem-calc(17);
    }
}
.lg {
    @include light;
    font-size: rem-calc(29);
    line-height: 1.4;
    margin-bottom: rem-calc(50);
}
.lg {
    p {
    @include light;
    font-size: rem-calc(29);
    line-height: 1.4;
    margin-bottom: rem-calc(50);
    }
}
.md {
    @include light;
    font-size: rem-calc(21);
    line-height: 1.4;
    margin-bottom: rem-calc(55);
}
.sm {
    font-size: rem-calc(16);
}
.sm {
    p {
        font-size: rem-calc(16);
    }
}
.xs {
    // font-size: rem-calc(14);
    font-size: rem-calc(15);
}

// imatge esquerra/dreta
.img-left,
.img-right {
  img {
    margin-bottom: $paragraph-margin-bottom;
  }
}
@media #{$medium-up} {
  .img-left {
    img {
      float: left;
      margin-right: $column-gutter/2;
    }
  }

  .img-right {
    img {
      float: right;
      margin-left: $column-gutter/2;
    }
  }
}

// llistes
.capabilities-list {
    list-style: none;
    font-size: rem-calc(20);
}
.capabilities-list__item {
    color: #b5e7ed;
    border-bottom: 1px solid $light-gray;
    padding: .5em 0;

    a {
        color: #b5e7ed;
    }

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }
}
.capabilities-list {
    ul {
    list-style: none;
    font-size: rem-calc(20);
    li {
        color: #b5e7ed;
        border-bottom: 1px solid $light-gray;
        padding: .5em 0;

        a {
            color: #b5e7ed;
        }

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }
            ul {
                li {
                    border-bottom: none;
                    font-size: rem-calc(18);
                    color: #fff;
                }
            }
        }
    }
}


// botons
.btn,
.button {
  @include button($padding: $button-sml);
  margin-bottom: $paragraph-margin-bottom;

  a {
    color: $white;
    text-decoration: none;
  }
}
.button-white {
  @include button($padding: $button-sml);
  margin-bottom: $paragraph-margin-bottom;
  border-color: #000000;
  color: #000000;

  a {
    color: #000000;
    text-decoration: none;
  }
  &:hover {
    background-color: #000000;
  }
}
.button-white.round {
    border-radius: 1000px;
}
.button-white.larger {
    @include demibold;
    font-size: rem-calc(22);
    line-height: 1.2;
    padding: rem-calc(23 40 22);

    @media #{$medium-up} {
        font-size: rem-calc(31);
        border-width: 3px;
        padding: rem-calc(23 60 22);
    }
}
.button-white.small {
     @include demibold;
        font-size: rem-calc(18);
        line-height: 1.2;
        padding-left: rem-calc(25);
        padding-right: rem-calc(25);
        padding: rem-calc(11 40 8);

        @media #{$medium-up} {
            font-size: rem-calc(26);
            border-width: 3px;
        }
}

a.button {
  text-decoration: none;
}

// imatge
.pict {
    margin-bottom: $column-gutter;

    &--offset {
        margin-top: rem-calc(-170);
    }
}
video {
    width: 100%;
    height: auto;
  }
.mt {
    margin-top: 30px;
}
.plus {
    padding-bottom: 8px;
    &:hover {
        opacity: .6;
    }

}
