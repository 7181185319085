// skip to main content
.skip {
  @include visually-hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $primary-color;
  color: $white !important;
  z-index: 10;
  outline: none;

  &:focus,
  &:active {
    clip: auto !important;
    width: auto !important;
    height: auto !important;
    clip: auto !important;
    padding: 1em !important;
  }
}
