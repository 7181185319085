// fitxa de feina
.view {

    &__header {
        background-color: #dedede;
    }

    &__title {
        text-transform: none;
        font-size: rem-calc(20);
        letter-spacing: 0;
        margin-bottom: 5rem;
        @media #{$medium-up} {
            font-size: rem-calc(40);
            margin-bottom: 7rem;
        }
    }

    .case__bg {
        margin-bottom: 0;
    }

    .case__img {
        top: -50px;
    }

    .floor--case {
        padding-top: rem-calc(90);
    }

    &__text {
        margin: rem-calc(70 0);
    }

    p,h3 {
        text-align: left;
    }
}

.dest {
    font-size: rem-calc(20);
    border-left: 1px solid #90282a;
    color: #90282a;
    padding-left: $paragraph-margin-bottom;
    line-height: 1.3;

    @media #{$medium-up} {
        font-size: rem-calc(25);
    }

    @media #{$large-up} {
        font-size: rem-calc(30);
    }
}
