.canal-denuncies {
    .floor:not(.floor--intro) {
        padding: 4rem 0;
        text-align: left;
    }
    .floor {
        &--intro {
            padding-bottom: 3rem;
        }
        &--intro-2 {
            @media #{$medium-up} {
                padding-bottom: 10rem !important;
            }
            @media #{$large-up} {
                padding-bottom: 12rem !important;
            }
        }
        &--solid {
            color: #fff;
            background-color: $denuncies-primary;
            padding-left: ($column-gutter / 2) !important;
            padding-right: ($column-gutter / 2) !important;
            h2:not(.section-title--contact) {
                color: #fff;
            }
        }
        &--solid-2 {
            color: $denuncies-primary;
            background-color: $denuncies-light;
        }
    }
    h2:not(.section-title--contact) {
        font-size: rem-calc(36);
        @include demibold;
        color: $denuncies-primary;
        text-align: left;
        .floor--solid & {
            color: #fff;
        }
    }
}
.denuncies-intro-img {
    margin-bottom: 2rem;
    @media #{$medium-up} {
        position: absolute;
        right: 3rem;
        max-width: none;
    }
}
.denuncies-grid {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    font-size: rem-calc(18);
    @include demibold;
    color: $denuncies-primary;
    line-height: 1.4;
    @media #{$medium-up} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media #{$large-up} {
        grid-template-columns: repeat(3, 1fr);
    }
    > * {
        background-color: $denuncies-light;
        padding: rem-calc(30) rem-calc(40) rem-calc(23);
    }
    svg {
        display: block;
        margin-bottom: rem-calc(28);
    }
}
.denuncies-btn {
    display: inline-flex;
    border: 2px solid $denuncies-primary;
    border-radius: 1000px;
    @include demibold;
    font-size: rem-calc(18);
    color: $denuncies-primary;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1.5rem;
    @include hover;
    .floor--solid & {
        filter: brightness(100);
    }
    &::after {
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M30 0v30H0V0z'/%3E%3Cpath stroke='%230C2C68' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M21.25 21.25 8.75 8.75M21.25 10v11.25H10'/%3E%3C/g%3E%3C/svg%3E");
    }
}
.proces-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: rem-calc(40);
    font-size: 1rem;
    color: $denuncies-primary;
    text-align: center;
    list-style: none;
    margin-left: 0;
    &--3 {
        @media #{$medium-up} {
            grid-template-columns: repeat(2, 1fr);
        }
        @media #{$large-up} {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &--2 {
        @media #{$medium-up} {
            grid-template-columns: 1fr 1fr;
            img {
                margin-top: 0 !important;
            }
        }
        @media #{$large-up} {
            grid-template-columns: 1fr 2fr;
        }
    }
    li {
        display: flex;
        flex-direction: column;
    }
    span {
        font-weight: bold;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        @include demibold;
        margin: 0 auto 1rem;
        background-color: $denuncies-primary;
        color: #fff;
        border-radius: 50%;
        width: rem-calc(29);
        height: rem-calc(29);
        line-height: 1;
    }
    img {
        display: block;
        margin: auto auto 0 auto;
    }
}

// pop-up form
.canal-denuncies {
    .mfp-bg {
        opacity: 0.3;
    }
}
.solicitud-form {
    background-color: #fff;
    text-align: left;
    max-width: 670px;
    margin: 3rem auto;
    position: relative;
    border-radius: 15px;
    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        > div {
            padding: rem-calc(50) rem-calc(50);
            @media #{$medium-up} {
                padding-left: rem-calc(125);
                padding-right: rem-calc(125);
            }
        }
    }
    legend {
        color: #fff;
        background-color: $denuncies-primary;
        padding: rem-calc(42) rem-calc(50);
        margin: 0;
        width: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        @include demibold;
        font-size: rem-calc(18);
        @media #{$medium-up} {
            padding-left: rem-calc(125);
            padding-right: rem-calc(125);
        }
    }
    p {
        color: $denuncies-primary;
        @include demibold;
        font-size: rem-calc(18);
        line-height: 1.4;
    }
    label {
        color: $denuncies-primary;
        font-size: 1rem;
        margin-bottom: 0.25rem;
    }
    input,
    textarea {
        border: 2px solid $denuncies-primary;
        background: #fff;
        border-radius: 15px;
        padding-left: 1rem;
        padding-right: 1rem;
        &:focus {
            border-color: #000;
        }
    }
    input {
        height: rem-calc(45);
    }
    #protecciodades,
    input[type="checkbox"] {
        border: 2px solid $denuncies-primary;
        position: absolute;
        height: 2.25rem;
        border-radius: 10px;
        &:checked::after {
            top: 7px;
            margin: 0;
            transform: translateX(-50%);
        }
    }
    span.text {
        margin-left: 50px;
        display: inline-block;
    }
    label[for="protecciodades"] a {
        color: $denuncies-primary;
    }
    button[type="submit"] {
        margin-top: 2rem;
        background-color: $denuncies-primary;
        width: 100%;
        border-radius: 26px;
        font-size: rem-calc(18);
        @include demibold;
        border: 2px solid $denuncies-primary;
        transition:
            background-color 0.125s ease-in,
            color 0.125s ease-in;
        position: relative;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        &:hover,
        &:focus {
            background-color: #fff;
            color: $denuncies-primary;
            &::after {
                filter: invert(100);
            }
        }
        &:after {
            content: "";
            display: block;
            width: 44px;
            height: 44px;
            background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M22 .787 43.213 22 22 43.213.787 22z'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M30.839 22H13.16M22.884 14.045 30.839 22l-7.955 7.955'/%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .mfp-close {
        color: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0;
        top: 25px;
        right: 25px;
        opacity: 100;
        @include light;
    }
}

// resposta form
.canal-denuncies {
    .form-sent {
        padding-top: 0 !important;
        > * + * {
            margin-top: 2.5rem;
        }
        > div > div > * {
            margin: 0;
            + * {
                margin-top: 2rem;
            }
        }
        h2 {
            text-align: center;
            + p {
                color: $denuncies-primary;
                @include demibold;
                font-size: rem-calc(18);
            }
        }
        .denuncies-btn {
            &::after {
                background-repeat: no-repeat;
            }
            &:hover,
            &:focus {
                text-decoration: none !important;
            }
            &--ok {
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='24.936' height='24.936' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.81 8.658 9.37 23.522l-7.956-7.955' stroke='%230C2C68' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                }
            }
            &--error {
                color: #fff;
                border-color: #f04124;
                background-color: #f04124;
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23FFF' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='m1.417 1.417 19.166 19.166M20.583 1.417 1.417 20.583'/%3E%3C/g%3E%3C/svg%3E");
                    position: relative;
                    top: 4px;
                }
            }
        }
    }
}
