.js-mobile-menu {
    @extend .ico-menu;
    display: block;
    // border: 1px solid red;
    // width: 30px;
    // height: 30px;
    color: $white;
    margin: 0 auto 30px;


    @media #{$medium-up} {
        display: none;
    }
}
