.main-header {
    background: black;
    text-align: center;

    .js-menu-open & {
        // box-shadow: 0 25px 200px -75px rgba(0,0,0,.8);
    }

    > .row > .column {
        position: static;
    }

    .base & {
        margin-bottom: $paragraph-margin-bottom*2;
    }

    .home & {
        background: transparent;
        border-bottom: 0;
        position: absolute;
        width: 100%;
        z-index: 1;

        .no-js & {
            position: static;
            background: black;

            @media #{$medium-up} {
                background: transparent;
                position: absolute;
            }
        }
    }

    .home.js-menu-open & {
        background: black;
        z-index: 1;
    }

    .home.js-contact-open & {
        background: black;
    }
    .tools {
            list-style: none;
            margin-top: rem-calc(29);
            margin-left: 0;
            li {
                display: inline-block;
                position:relative;
                padding-right: rem-calc(10);
                padding-left: rem-calc(10);
                min-height: rem-calc(20);
                margin-left: rem-calc(-5);
                 &:after {
                    content: '';
                    height: 100%;
                    width: 1px;
                    background-color: #fff;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 0.31;
                }
                &:last-child{
                  padding-right: rem-calc(0);
                  &:after {
                    content: none;
                  }
                }
                a {
                    font-size: rem-calc(10);
                    color: #f8e71c;
                    line-height: rem-calc(20);
                    letter-spacing: 1.8px;
                    height: rem-calc(20);
                    text-decoration: none;


                    &.active {
                        color: $white;
                        font-family: 'Barlow';//'sisco_heavyregular';
                        font-weight: 800;
                    }
                    &:hover {
                        text-decoration: underline;

                    }
                }
            }
        }



     @media #{$medium-up} {
        .base & {
            margin-bottom: 0;
        }
        .js & {
            position: fixed;
            width: 100%;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 2;
        }
        .js .home & {
            position: absolute;
        }

        .tools {
            position: absolute;
            list-style: none;
            right: 10%;
            margin-top: rem-calc(29);

        }
    }
     @media #{$medium-only} {
        .tools {
            position: absolute;
            list-style: none;
            right: 2%;
            margin-top: rem-calc(19);

        }

    }

    }

.home .tools {
    margin-top: rem-calc(-2);
}
.main-nav__title {
    @include visually-hidden;
}
.logo {
    margin: rem-calc(30) auto rem-calc(20);
    display: inline-block;
    font-size: 1em;
    letter-spacing: 0;

    @media #{$medium-up} {
        margin-top: rem-calc(24);
        float: left;
        margin-right: -105px;

        .home & {
            margin: rem-calc(44) auto rem-calc(16);
            display: inline-block;
            float: none;
        }
    }

    @media #{$medium-only} {
        float: none;
        margin-bottom: 0;
        margin-right: 0;
    }

    a.logo--desktop {
        @extend .logo-antaviana;
        display: none;

        @media #{$medium-up} {
            display: block;
        }
    }

    img {
        @media #{$medium-up} {
            display: none;
        }
    }

}
