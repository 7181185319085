.main-nav {

    .js & {
        display: none;

        @media #{$medium-up} {
            display: block;
        }
    }

    // menú principal
    &__menu {
        @include demibold;
        list-style: none;
        margin: rem-calc(36 0 24 0);
        text-transform: uppercase;
        font-size: rem-calc(14);
        letter-spacing: 1.8px;

        .home & {
            // margin-top: 0;
        }

        @media #{$medium-up} {
            margin: 0;
        }
    }

    // item de menú
    &__item {

        // mòbil
        &:first-child {
            > a {
                @media #{$small-only} {
                    padding-top: 0;

                    &:before {
                        display: none;
                    }
                }

            }
        }

        // desktop
        @media #{$medium-up} {
            display: inline-block;
            margin: 0 1em;
            padding: rem-calc(32 0 21);

            .home & {
                padding: 0;
            }

            // border hover
            &:hover {
                > a {
                    position: relative;

                    &:after {
                       height: 3px;
                       background: $white;
                       background: rgba($white,.25);
                    }
                }
            }

            // border element actiu
            &--active {
                > a {
                    position: relative;

                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 3px !important;
                        background: $white !important;
                        background: rgba($white,.25) !important;
                        position: absolute;
                        left: 0;
                        bottom: 14px;
                    }
                }
            }
        }

        @media #{$medium-only} {
            padding-top: rem-calc(22);
        }

        // vincle
        > a {
            color: $white;
            display: block;
            padding: .9em 0;
            position: relative;

            &:hover {
                text-decoration: none;
            }

            // border decoratiu per vincles mòbil
            @media #{$small-only} {
                &:before {
                    content: '';
                    width: 42px;
                    height: 1px;
                    background-color: #979797;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    margin-left: -21px;
                }
            }

            // vincles desktop
            @media #{$medium-up} {
                display: inline;
                padding: rem-calc(33 0 22);

                &:hover {
                    text-decoration: none;
                }

                // border transparent preparat per a la transició
                &:after {
                   content: '';
                   display: block;
                   width: 100%;
                   height: 1px;
                   background: transparent;
                   position: absolute;
                   left: 0;
                   bottom: 14px;
                   transition: background-color .2s, height .2s;
                }
            }
            @media #{$medium-only} {
                padding-top: rem-calc(23);
            }
        }

        // activació submenú amb hover
        @media #{$medium-up} {
            &--has-dropdown {

                > a {
                    // preparació fletxeta per a la transició
                    &:after {
                        @include triangle(8px, $white, bottom);
                        content: '';
                        display: block;
                        position: absolute;
                        left: 50%;
                        bottom: -16px;
                        margin-left: -8px;
                        background: transparent;
                        opacity: 0;
                        transition: bottom .1s, opacity .1s;
                    }
                }
                &:hover,
                &:focus {
                    .sub-nav {
                        opacity: 1;
                        top: 75px;

                        @media #{$medium-only} {
                            top: 120px;
                        }
                    }

                    > a {
                        position: relative;

                        // apareix fletxeta al hover
                        &:after {
                            bottom: 0;
                            background: transparent;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

// submenú
.sub-nav {
    @media #{$medium-up} {
        opacity: 0;
        position: absolute;
        left: 0;
        top: -1000px;
        width: 100% !important;
        margin: 0 auto;
        padding: rem-calc(45 0 25);
        background-color: $white;
        box-shadow: 0 25px 200px -75px rgba(0,0,0,.8);
        z-index: -1;
        transition: opacity .1s ease-in;
    }

    &__container {
        @include grid-row;
    }

    &__menu {
        @include book;
        list-style: none;
        margin: 0 0 rem-calc(5);
        font-size: rem-calc(11);
        letter-spacing: 1.6px;

        @media #{$medium-up} {
            @include demibold;
            font-size: rem-calc(14px);
            text-align: left;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin: 0;
        }
    }

    &__item {

        // fila superior
        &--webs {
            a:before {
                @extend .ico-menu-webs;
                margin-top: ($ico-menu-eines-height - $ico-menu-webs-height)/2;
            }
        }
        &--intranets {
            a:before {
                @extend .ico-menu-intranets;
                margin-top: ($ico-menu-eines-height - $ico-menu-intranets-height)/2;
            }
        }
        &--eines-a-mida {
            a:before {
                @extend .ico-menu-eines;
            }
        }
        &--cms {
            a:before {
                @extend .ico-menu-gestors;
                margin-top: ($ico-menu-eines-height - $ico-menu-gestors-height)/2;
            }
        }
        &--comunicacio {
            a:before {
                @extend .ico-menu-comunicacio;
                margin-top: ($ico-menu-eines-height - $ico-menu-comunicacio-height)/2;
            }
        }

        // fila inferior
        &--dg {
            a:before {
                @extend .ico-menu-disseny;
            }
        }
        &--illustracio {
            a:before {
                @extend .ico-menu-ilustracio;
                margin-top: ($ico-menu-disseny-height - $ico-menu-ilustracio-height)/2;
            }
        }
        &--tipografia {
            a:before {
                @extend .ico-menu-tipografia;
                margin-top: ($ico-menu-disseny-height - $ico-menu-tipografia-height)/2;
            }
        }
        &--apps {
            a:before {
                @extend .ico-menu-progressive-web-apps;
                margin-top: ($ico-menu-disseny-height - $ico-menu-progressive-web-apps-height)/2;
            }
        }
        &--infografies {
            a:before {
                @extend .ico-menu-infografies;
                margin-top: ($ico-menu-disseny-height - $ico-menu-infografies-height)/2;
            }
        }

        // vincles submenú mòbil
        a {
            color: $white;
            display: block;
            margin-bottom: rem-calc(20);
            position: relative;

            &:hover {
                text-decoration: none;
            }

            // vincles submenú desktop
            @media #{$medium-up} {
                color: #4a4a4a;
                width: 20%;
                height: rem-calc(93);
                float: left;
                border-left: 1px solid $gray;
                padding: rem-calc(0 0 0 20);
                position: relative;

                &:before {
                    display: block;
                    position: absolute;
                    left: 20px;
                    top: 2px;
                    content: '';
                }

                &:hover {
                    span {
                        text-decoration: underline;
                    }
                }

                span {
                    display: inline;
                    position: absolute;
                    left: 20px;
                    top: 65px;
                    line-height: 1.1;
                }
            }
        }
    }
}
