.home-video{
  position:relative;
  //max-height: 550px;
  overflow: hidden;
  background-color: #6c1d41;
  //min-height: 400px;

  video{
    width:100%;
    //min-height: 400px;
  }
  .overlay{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,.45);
    z-index: 1;
  }
  .home-intro{
    position: absolute;
    z-index:3;
    top:50%;
    left:50%;
    color:#fff;
    transform: translate(-50%,-50%);
    width:100%;
    max-width: 900px;
    h2{
      font-size: rem-calc(20);
      font-family: lato_light,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
      font-weight: 300;
      strong{
        font-size:rem-calc(28);
      }
      span{
        font-size:rem-calc(50);
        text-transform:none;
        text-shadow: 0 2px 3px rgba(0,0,0,.4);
        strong{
          font-size:rem-calc(50);
        }

      }

    }
  }
  .down-link {
            display: none;
            @media #{$large-up} {
                display: block;
                position: absolute;
                bottom: 25px;
                right: 0;   
                left: 49.5%;
                z-index: 1;
            }
        }
    .header-vid-home {
      position: relative;
    }

}
.fluid-width-video-wrapper {
  margin-bottom: rem-calc(-8);
}