.serveis-disseny {
    .floor--intro {
        padding-bottom: 230px;
    }

    .img-serveis {
        position: absolute;
        top: -100px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    p {
        font-size: rem-calc(16);
    }
    h2.title-servd {
        font-size: 38px;
        color: #000;
        text-align: left;
        font-weight: 600;
        padding-top: 100px;
        position: relative;
        margin-bottom: 50px;
        &:before {
            content:'';
            position: absolute;
            bottom: 20px;
            left: -102%;
            width: 100%;
            height: .5px;
            background-color: #000;
            transform: translateY(-50%);
            z-index: 0;
        }
    }
    .button {
        padding: 0.75rem 2.125rem!important;
        display: block;
        max-width: 300px;
        margin: 0 auto 30px;
        &:hover {
            color: #000;
            background-color: #FFF;
            text-decoration: none;
        }

    }
    .mt-30 {
        @media #{$medium-up} {
            margin-top: 30px;
        }
    }
    .mt-80 {
        @media #{$medium-up} {
            margin-top: 80px;
        }
    }
    .box-white {
        background-color: #fff;
        border-radius: 10px;
        padding: 30px;
        position: relative;
        margin-bottom: 30px;

        @media #{$medium-up} {
            margin-bottom: 0;
        }
        h3 {
            font-size: rem-calc(28);
            font-family: 'Barlow';//'sisco_heavyregular';
            font-weight: 800;
            margin-bottom: 19px;
            color: #000;
            line-height: 32px;
        }
        p {
            font-size: rem-calc(14);
            color: #000;
        }
        .bottom-box {
            position: absolute;
            bottom: 30px;
        }
        ul.icons {
            list-style: none;
            margin-left: 0;
            margin-bottom: 0px;
            li {
                display: inline-block;
                margin-right: 10px;
            }
        }
        .dest-preu {
            font-size: rem-calc(16);
            font-family: 'Barlow';//font-family: 'sisco_lightregular';
            font-weight: 300;
            .preu {
                font-size: rem-calc(24);
                span {
                    font-size: rem-calc(16);
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    .floor--serveid-1 {
        position: relative;
        text-align: left;
        color: #000;
        h2.title-servd {
            padding-top: 100px;
            margin-bottom: 50px;
            &:before {
                bottom: 20px;
            }
        }
        .box-white {
            height: 314px;
            @media #{$medium-up} {
                height: 380px;
            }
            @media #{$large-up} {
                height: 314px;
            }
        }
    }
    .disseny-editorial {
        h2.title-servd {
            padding-top: 50px;
        }
        .box-white {
            height: 300px;
            @media #{$medium-up} {
                height: 340px;
            }
            @media #{$large-up} {
                height: 300px;
            }
        }
    }
    .floor--serveid-2 {
        position: relative;
        text-align: left;
        color: #000;
        h2.title-servd {
            color: #fff;
            padding-top: 50px;
            &:before {
                background-color: #fff;
            }
        }
        a {
            color: white;
        }
        .box-white {
            height: 345px;
            @media #{$medium-up} {
                height: 400px;
            }
            @media #{$large-up} {
                height: 345px;
            }
        }
        .mides {
            font-size: rem-calc(11);
            list-style: none;
        }
    }

    .floor--serveid-3 {
        position: relative;
        text-align: left;
        color: #000;
        padding-bottom: 200px;
        h2.title-servd {
            color: #fff;
            font-family: 'Barlow';//'sisco_heavyregular';
            font-weight: 800;
            padding-top: 50px;
            span {
                display: block;
                font-family: 'Barlow';//'sisco_lightregular';
                font-weight: 300;
            }
            &:before {
                background-color: #fff;
                bottom: 65px;
            }
        }
        .box-white {
            height: 248px;
            @media #{$medium-up} {
                height: 300px;
            }
            @media #{$large-up} {
                height: 248px;
            }
            .hores {
                font-size: rem-calc(70);
                font-family: 'Barlow';//'sisco_heavyregular';
                font-weight: 800;
                line-height: 40px;
                padding-top: 20px;
                span {
                    display: block;
                    font-size: rem-calc(18);
                    font-family: 'Barlow';//font-family: 'sisco_demiboldregular';
                    font-weight: 600;
                }
            }
        }
    }
    .floor--serveid-4 {
        position: relative;
        padding-bottom: 150px;
        .img-serveis-peu {
            position: absolute;
            top: -100px;
            left: 50%;
            transform: translate(-50%, -50%);
        }

    }
}
.text-doscols {
    text-align: left;
    color: #000;
    h2 {
        font-size: rem-calc(30);
        font-family: 'Barlow';//font-family: 'sisco_demiboldregular';
        font-weight: 600;
        text-align: left;
        color: #000;
    }
    h3 {
        text-align: left;
        font-size: rem-cacl(22);
        font-family: 'Barlow';//'sisco_bookregular';
        font-weight: 400;
        color: #000;
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(15);
    }
    p {
        font-size: rem-calc(14);
        text-align: left;
    }
    .main-footer {
        margin-top: 0;
    }
    hr {
        border: solid #979797;
        border-width: 1px 0 0;
        margin: 280px 0 50px;
    }
}
