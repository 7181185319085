// feines
.case {
    &__bg {
        background-color: $white;
        background-size: cover;
        background-position: 50% 50%;
        min-height: rem-calc(135);
        margin-bottom: rem-calc(35);
        position: relative;

        .sr & {
            // visibility: hidden;
        }

        @media #{$medium-up} {
            min-height: rem-calc(350);
            margin-bottom: rem-calc(140);
        }

        @media #{$large-up} {
            min-height: rem-calc(400);
            margin-bottom: rem-calc(200);
        }

        @media #{$xlarge-up} {
            min-height: rem-calc(490);
        }

        &--1 {
            background-image: url('img/case-bg-1.jpg');
            @media #{$retina} {
                background-image: url('img/case-bg-1@2x.jpg');
            }
        }

        &--2 {
            background-image: url('img/case-bg-2.jpg');
            @media #{$retina} {
                background-image: url('img/case-bg-2@2x.jpg');
            }
        }

        &--3 {
            background-image: url('img/case-bg-3.jpg');
            @media #{$retina} {
                background-image: url('img/case-bg-3@2x.jpg');
            }
        }
        &--6 {
            background-image: url('img/case-bg-4.jpg');
            @media #{$retina} {
                background-image: url('img/case-bg-4@2x.jpg');
            }
        }
    }
    &__img {
        width: 205px;
        position: absolute;
        left: 50%;
        top: -75px;
        margin-left: -70px;

        .sr & {
            // visibility: hidden;
        }

        @media #{$medium-up} {
            width: 300px;
            top: auto;
            bottom: -220px;
            margin-left: -102px;
        }

        @media #{$large-up} {
            width: auto;
            margin-left: -113px;
            bottom: -250px;
        }
    }
    &__title {
        @include light;
        font-size: rem-calc(30);
        line-height: 1.2;
        margin-bottom: $paragraph-margin-bottom*2;
        color: $white;
        .floor--case-1 & {
            color: $white;
        }

        .floor--case-2 & {
            color: #ab6c10;

            @media #{$medium-up} {
                color: $white;
            }
        }

        .floor--case-3 & {
            color: $white;
        }
        .floor--case-6 & {
            color: $white;
        }

        @media #{$medium-up} {
            font-size: rem-calc(40);
            position: absolute;
            top: rem-calc(-340);
            width: 100%;
            margin-bottom: 0;
        }

        @media #{$large-up} {
            top: rem-calc(-430);
        }

        @media #{$xlarge-up} {
            top: rem-calc(-500);
        }
    }
    &__subtitle {
        @include light;
        text-align: left;
        font-size: rem-calc(20);
        color: $white;
        line-height: 1.2;
        margin-bottom: $paragraph-margin-bottom;

        .floor--case-1 & {
            color: $white;
        }

        .floor--case-2 & {
            color: #ab6c10;
        }

        @media #{$medium-up} {
            font-size: rem-calc(26);
        }

        @media #{$large-up} {
            font-size: rem-calc(34);
        }
    }
    &__text {
        text-align: left;
        color: $white;
        font-size: rem-calc(16);
        margin-bottom: $paragraph-margin-bottom*2;

        .floor--case-1 & {
            color: $white;
        }

        .floor--case-2 & {
            color: #4a4a4a;
        }
    }
    &__button {
        margin-bottom: $paragraph-margin-bottom*2;

        .floor--case-1 & {
            color: $white;
            border-color: $white;
        }

        .floor--case-2 & {
            color: #4a4a4a;
            border-color: #4a4a4a;
        }

        .floor--case-3 & {
            color: $white;
            border-color: $white;
        }
    }
}

// altres-feines

// colors feines
$igtp: #4a90e2;
$rh: #1ca4a6;
$appriver: #0053a0;
$cloud: #1C5E3E;
$fsjd: #4A9EDF;
$fmiro: #fe6345;
$mb: #e15f29;
$bib: #82b304;
$ics: #037CCF;
$icsIN: #F1F1F1;
$mar: #F88106;
$clinic: #067241;
$icn2: #006EB6;
$irb: #6C1D41;
$endp: #94A100;
$tango: #f7f7f7;
$calvis: #f7f7f7;
$iislafe: #0157a4;
$irycis: #009b8b;
$irb: #d76398;
$csf: #134189;
$ssibe: #00528C;
$bopb: #891536;
$redmo: #333;

.altres-feines__item {
    margin: 0 auto $column-gutter;
    width: rem-calc(300);
    height: rem-calc(300);
    text-align: center;
    transition: background-color .2s ease-in;
    background-color: $gray;
    position: relative;

    .sr & {
        visibility: hidden;
    }

    &--igtp {
        background-color: $igtp;
        @include darken($igtp);
    }
    &--csf {
        background-color: $csf;
        @include darken($csf);
    }
    &--ssibe {
        background-color: $ssibe;
        @include darken($ssibe);
    }
    &--bopb {
        background-color: $bopb;
        @include darken($bopb);
    }
    &--redmo {
        background-color: $redmo;
        @include darken($redmo);
    }
    &--rh {
        background-color: $rh;
        @include darken($rh);
    }
    &--appriver {
        background-color: $appriver;
        @include darken($appriver);
    }
    &--cloud {
        background-color: $cloud;
        @include darken($cloud);
    }
    &--endp {
        background-color: $endp;
        @include darken($endp);
    }
    &--iislafe {
        background-color: $iislafe;
        @include darken($iislafe);
    }
    &--irb {
        background-color: $irb;
        @include darken($irb);
    }
    &--fsjd {
        background-color: $fsjd;
        @include darken($fsjd);
    }
    &--fmiro {
        background-color: $fmiro;
        @include darken($fmiro);
    }
    &--mb {
        background-color: $mb;
        background-image: linear-gradient(45deg, #fee24e, #e15f29);
        &:hover {
             background-color: darken($mb,2%);
             background-image: linear-gradient(45deg, darken(#fee24e,2%), darken(#e15f29,2%));
         }
    }
    &--bib {
        background-color: $bib;
        @include darken($bib);
    }

    &:hover,
    &:focus {
        .altres-feines__info {
             opacity: 1;
         }
    }

    @media #{$medium-up} {
        width: 100%;
        overflow: hidden;
    }

    a {
        display: block;
        padding: 0 $column-gutter;

            cursor: default; // provisional
    }
}
.altres-feines__item_salut {
    margin: 0 auto $column-gutter;
    width: rem-calc(220);
    height: rem-calc(220);
    text-align: center;
    transition: background-color .2s ease-in;
    background-color: $gray;
    position: relative;


    &--ics {
        background-color: $ics;
        @include darken($ics);
    }
    &--icsIN {
        background-color: $icsIN;
        @include darken($icsIN);
    }
    &--mar {
        background-color: $mar;
        @include darken($mar);
    }
     &--clinic {
        background-color: $clinic;
        @include darken($clinic);
    }
    &--igtp {
        background-color: $igtp;
        @include darken($igtp);
    }
    &--iislafe {
        background-color: $iislafe;
        @include darken($iislafe);
    }
    &--irycis {
        background-color: $irycis;
        @include darken($irycis);
    }
    &--icn2 {
        background-color: $icn2;
        @include darken($icn2);
    }
     &--irb {
        background-color: $irb;
        @include darken($irb);
    }
    &--tango {
        background-color: $tango;
        @include darken($tango);
    }
    &--calvis {
        background-color: $calvis;
        @include darken($calvis);
    }
    &--mb {
        background-color: $mb;
        background-image: linear-gradient(45deg, #fee24e, #e15f29);
        &:hover {
             background-color: darken($mb,2%);
             background-image: linear-gradient(45deg, darken(#fee24e,2%), darken(#e15f29,2%));
         }
    }
    &--bib {
        background-color: $bib;
        @include darken($bib);
    }

    &:hover,
    &:focus {
        .altres-feines__info {
             opacity: 1;
         }
    }

    @media #{$medium-up} {
        width: 100%;
        overflow: hidden;
    }

    a {
        display: block;
        padding: 0 $column-gutter;

            cursor: default; // provisional
    }
}

.altres-feines__title {
    margin: 0;
    line-height: 300px;
}
.altres-feines__title_salut {
    margin: 0;
    line-height: 220px;
}

.altres-feines__info {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $black;
    background-color: rgba(0,0,0,.5);
    line-height: 1.2;
    transition: opacity .2s ease-in;
    color: $white;
    text-align: left;
    padding: rem-calc(35);
    .altres-feines__title-2 {
        font-size: rem-calc(29);
        @include light;
        line-height: rem-calc(32);
    }
    .altres-feines__descr {
        font-size: rem-calc(16);
        @include light;
        line-height: rem-calc(22);
    }
}
.altres-feines__text {
    margin: 0 auto;
    width: 70%;
}
// desplegable "Més feines"
// .mes-feines-btn {
//     display: none;
//     .js & {
//         display: inline-block;
//         clear: left;
//     }
// }
// .mes-feines {
//     .js & {
//         display: none;
//     }
// }
