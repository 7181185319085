.utils-intranet {
    list-style: none;
    margin-left: -$column-gutter/2;
    margin-right: -$column-gutter/2;

    @media only screen and (min-width: em-calc(480)) {
        @include block-grid(2);
    }

    @media #{$medium-up} {
        @include block-grid(3);
    }

    @media #{$large-up} {
        @include block-grid(4);
    }
}
.utils-intranet__item {
    float: left;
    margin-bottom: rem-calc(100);
    width: 100%;
    padding: 0 $column-gutter/2;
    font-size: rem-calc(18);
    line-height: 1.3;
    color: $white;

    .sr & {
        visibility: hidden;
    }

    &:before {
        content: '';
        display: block;
        margin: 0 auto rem-calc(23);
    }

    &--1 {

        &:before {
            @extend .ico-intranets-organitzar;
        }
    }
    &--2 {
        &:before {
            @extend .ico-intranets-comunicacio;
        }
    }
    &--3 {
        &:before {
            @extend .ico-intranets-eliminarpaper;
        }
    }
    &--4 {
        &:before {
            @extend .ico-intranets-eines;
        }
    }
    &--5 {
        &:before {
            @extend .ico-intranets-membres;
        }
    }
    &--6 {
        &:before {
            @extend .ico-intranets-costos;
        }
    }
    &--7 {
        &:before {
            @extend .ico-intranets-empresa;
            margin-top: -18px;
        }
    }
}
