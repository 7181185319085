.error {
    text-align: center;
    h1 {
        margin-bottom: rem-calc(12);
    }
    p {
      font-size: rem-calc(17);
      @include demibold;
      color: #4A4A4A;
    }
    .segon {
       @include book; 
       font-size: rem-calc(15);
    }
    .back-error {
        margin-top: rem-calc(80);
        margin-bottom: rem-calc(60);
        height: rem-calc(214);
        background-image: url('img/error-404.png');
        @media #{$medium-up} {
               margin-top: rem-calc(174);
            }
    }
    .button {
        border-color: #000000;
        color: #000000;
        &:hover {
            color: #FFF;
            background-color: #000000;
        }
    }
    ul {
        list-style: none;
        margin-top: rem-calc(54);
        li {
            display: inline-block;
            margin-left: rem-calc(15);
            margin-right: rem-calc(15);
        }
    }
} 