.animation-container-1 {
    position: relative;
    padding-top: 1042px;
    background-color: #000;
    overflow: hidden;
}
.animation-container-2 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1371px;
    height: 100%;
    opacity: 0;
    animation: home-fadeIn 2s ease-in 1s forwards;
    @media only screen and (max-width: 1045px) {
        transform: translate(-50%, -40%);
    }
    @media only screen and (max-width: 700px) {
        transform: translate(-50%, -60%);
    }
    > img {
        max-width: none;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        @media only screen and (max-width: 700px) {
            transform: translate(-50%, -29%);
        }
    }
    .text {
        position: absolute;
        left: 15%;
        top: 12rem;
        @media only screen and (max-width: 1045px) {
            left: 50%;
            transform: translateX(-50%);
            top: 6rem;
            width: 45%;
        }
        @media only screen and (max-width: 700px) {
            top: 16rem;
            width: 25%;
        }
        h2 {
            text-align: left;
            max-width: 25ch;
            margin-bottom: 2rem;
            font-size: clamp(2rem, 2.5vw, 5rem);
            @media only screen and (max-width: 1045px) {
                text-align: center;
                max-width: none;
                margin-bottom: 1.5rem;
            }
        }
        p {
            color: #fff;
            max-width: 37ch;
            @media only screen and (max-width: 1045px) {
                max-width: none;
                text-align: center;
            }
        }
        strong {
            color: #b5e7ed;
        }
    }
}

// actions
.actions {
    opacity: 0;
    animation: home-fadeIn 1s ease-out 3s forwards;
    div {
        position: absolute;
        z-index: 2;
        &.anim-2 {
            span {
                &:first-child img {
                    animation: anim-2-1 2s steps(1) infinite;
                }
                &:last-child {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: anim-2-2 2s steps(1) infinite;
                    }
                }
            }
        }
        &.anim-3 {
            span {
                &:nth-child(1) img {
                    animation: anim-3-1 1s steps(1) infinite alternate;
                }
                &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: anim-3-2 1s steps(1) infinite alternate;
                    }
                }
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: anim-3-3 1s steps(1) infinite alternate;
                    }
                }
            }
        }
        &.estanteria-llibres {
            span {
                &:nth-child(1) img {
                    animation: estanteria-1 2s steps(1) infinite alternate;
                }
                &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: estanteria-2 2s steps(1) infinite alternate;
                    }
                }
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: estanteria-3 2s steps(1) infinite alternate;
                    }
                }
            }
        }
        &.transportista {
            span {
                &:nth-child(1) img {
                    animation: transportista-1 3s steps(1) infinite;
                }
                &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: transportista-2 3s steps(1) infinite;
                    }
                }
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: transportista-3 3s steps(1) infinite;
                    }
                }
            }
        }
        &.codi {
            span {
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
                &:nth-child(4) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
            }
            &:hover {
                span {
                    &:nth-child(2) img {
                        animation: codi-1 1.5s steps(1) infinite alternate;
                    }
                    &:nth-child(3) {
                        img {
                            animation: codi-2 1.5s steps(1) infinite alternate;
                        }
                    }
                    &:nth-child(4) {
                        img {
                            animation: codi-3 1.5s steps(1) infinite alternate;
                        }
                    }
                }
            }
        }
        &.noia-telefon {
            span {
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
            }
            &:hover {
                span {
                    &:nth-child(2) img {
                        animation: noia-telefon-1 1.25s steps(1) infinite;
                    }
                    &:nth-child(3) {
                        img {
                            animation: noia-telefon-2 1.25s steps(1) infinite;
                        }
                    }
                }
            }
        }
        &.ma-negra {
            span {
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
                &:nth-child(4) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
            }
            &:hover {
                span {
                    &:nth-child(2) img {
                        animation: ma-negra-1 1.5s steps(1) infinite;
                    }
                    &:nth-child(3) {
                        img {
                            animation: ma-negra-2 1.5s steps(1) infinite;
                        }
                    }
                    &:nth-child(4) {
                        img {
                            animation: ma-negra-3 1.5s steps(1) infinite;
                        }
                    }
                }
            }
        }
        &.ma-blava {
            span {
                &:first-child img {
                    animation: ma-blava-1 4s steps(1) infinite;
                }
                &:last-child {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: ma-blava-2 4s steps(1) infinite;
                    }
                }
            }
        }
        &.pantalla-flotant {
            animation: pantalla-flotant-despl 4s ease-in-out infinite alternate-reverse;
            span {
                &:nth-child(1) img {
                    animation: pantalla-flotant-1 5s infinite;
                }
                &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: pantalla-flotant-2 5s infinite;
                    }
                }
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: pantalla-flotant-3 5s infinite;
                    }
                }
            }
        }
        &.noi-pantalla {
            animation: noi-pantalla-despl 4s ease-out infinite alternate;
            span {
                &:nth-child(1) img {
                    animation: noi-pantalla-1 1.25s steps(1) infinite;
                }
                &:nth-child(2) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: noi-pantalla-2 1.25s steps(1) infinite;
                    }
                }
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: noi-pantalla-3 1.25s steps(1) infinite;
                    }
                }
            }
        }
        &.impressora {
            span {
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
                &:nth-child(4) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
            }
            &:hover {
                span {
                    &:nth-child(2) img {
                        animation: impressora-1 1s steps(1) infinite;
                    }
                    &:nth-child(3) {
                        img {
                            animation: impressora-2 1s steps(1) infinite;
                        }
                    }
                    &:nth-child(4) {
                        img {
                            animation: impressora-3 1s steps(1) infinite;
                        }
                    }
                }
            }
        }
        &.noia-esquena {
            span {
                &:nth-child(3) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                    }
                }
            }
            &:hover {
                animation: noia-esquena-despl 3s ease-out infinite;
                span {
                    &:nth-child(2) img {
                        animation: noia-esquena-1 3s steps(1) infinite;
                    }
                    &:nth-child(3) {
                        img {
                            animation: noia-esquena-2 3s steps(1) infinite;
                        }
                    }
                }
            }
        }
        &.noia-pissarra {
            span {
                &:first-child img {
                    animation: noia-pissarra-1 4s steps(1) infinite;
                }
                &:last-child {
                    position: absolute;
                    top: 0;
                    left: 0;
                    img {
                        opacity: 0;
                        animation: noia-pissarra-2 4s steps(1) infinite;
                    }
                }
            }
        }
        &:hover {
            a.link {
                // ocultem imatge fixa
                img {
                    opacity: 0;
                }
                // mostrem imatges de l'animació
                + * {
                    opacity: 1;
                }
            }
        }
    }

    a.link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        // ocultem imatges de l'animació sota el link
        + * {
            opacity: 0;
        }
    }

    // posicionament
    .noia-telefon {
        top: 66%;
        left: 51%;
        @media only screen and (max-width: 700px) {
            top: 80%;
        }
    }
    .estanteria-llibres {
        top: 57.5%;
        left: 43%;
        @media only screen and (max-width: 700px) {
            top: 71.5%;
        }
    }
    .codi {
        top: 37.5%;
        left: 47.75%;
        @media only screen and (max-width: 700px) {
            top: 51.5%;
        }
    }
    .cercador {
        top: 24%;
        left: 82%;
    }
    .ma-blava {
        top: 59.25%;
        left: 16.4%;
        @media only screen and (max-width: 700px) {
            top: 73.4%;
        }
    }
    .ma-negra {
        top: 68%;
        left: 10.25%;
        @media only screen and (max-width: 700px) {
            top: 81%;
        }
    }
    .impressora {
        top: 69.5%;
        left: 36.5%;
        @media only screen and (max-width: 700px) {
            top: 83.5%;
        }
    }
    .noi-pantalla {
        top: 36%;
        left: 70%;
        @media only screen and (max-width: 700px) {
            top: 50%;
        }
    }
    .noia-esquena {
        top: 53%;
        left: 73%;
        @media only screen and (max-width: 700px) {
            top: 67%;
        }
    }
    .noi-taula {
        top: 55.5%;
        left: 51.25%;
        @media only screen and (max-width: 700px) {
            top: 70%;
        }
    }
    .noia-pissarra {
        top: 66%;
        left: 60.75%;
        @media only screen and (max-width: 700px) {
            top: 80%;
        }
    }
    .noia-taburet {
        top: 45.5%;
        left: 88.75%;
    }
    .transportista {
        top: 42%;
        left: 76%;
    }
    .pantalla-flotant {
        top: 32%;
        left: 64.5%;
        @media only screen and (max-width: 700px) {
            top: 46%;
        }
    }
}

// Animacions
// ----------
// estanteria
@keyframes estanteria-1 {
    30% {
        opacity: 0;
    }
}
@keyframes estanteria-2 {
    30% {
        opacity: 1;
    }
    55% {
        opacity: 0;
    }
}
@keyframes estanteria-3 {
    55% {
        opacity: 1;
    }
}
// transportista
@keyframes transportista-1 {
    40% {
        opacity: 0;
    }
}
@keyframes transportista-2 {
    40% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes transportista-3 {
    60% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
}
// codi
@keyframes codi-1 {
    33% {
        opacity: 0;
    }
}
@keyframes codi-2 {
    33% {
        opacity: 1;
    }
    66% {
        opacity: 0;
    }
}
@keyframes codi-3 {
    66% {
        opacity: 1;
    }
}
// ma-negra
@keyframes ma-negra-1 {
    15% {
        opacity: 0;
    }
}
@keyframes ma-negra-2 {
    15% {
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
}
@keyframes ma-negra-3 {
    20% {
        opacity: 1;
    }
}
// ma-blava
@keyframes ma-blava-1 {
    15% {
        opacity: 0;
    }
}
@keyframes ma-blava-2 {
    15% {
        opacity: 1;
    }
}
// impressora
@keyframes impressora-1 {
    33% {
        opacity: 0;
    }
}
@keyframes impressora-2 {
    33% {
        opacity: 1;
    }
    66% {
        opacity: 0;
    }
}
@keyframes impressora-3 {
    66% {
        opacity: 1;
    }
}
// noia-esquena
@keyframes noia-esquena-1 {
    66% {
        opacity: 0;
    }
}
@keyframes noia-esquena-2 {
    66% {
        opacity: 1;
    }
}
@keyframes noia-esquena-despl {
    0% {
        transform: translate(0, 0);
    }
    30% {
        transform: translate(-6%, 0);
    }
    60% {
        transform: translate(-2%, 0);
    }
}
// noia-telefon
@keyframes noia-telefon-1 {
    60% {
        opacity: 0;
    }
}
@keyframes noia-telefon-2 {
    60% {
        opacity: 1;
    }
}
// noia-pissarra
@keyframes noia-pissarra-1 {
    80% {
        opacity: 0;
    }
}
@keyframes noia-pissarra-2 {
    80% {
        opacity: 1;
    }
}
// noi-pantalla
@keyframes noi-pantalla-1 {
    25% {
        opacity: 0;
    }
}
@keyframes noi-pantalla-2 {
    25% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes noi-pantalla-3 {
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
}
@keyframes noi-pantalla-despl {
    // 0% { transform: translate(0,0); }
    100% {
        transform: translate(-20%, -30%);
    }
}
// pantalla flotant
@keyframes pantalla-flotant-1 {
    40% {
        opacity: 0;
    }
}
@keyframes pantalla-flotant-2 {
    40% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
}
@keyframes pantalla-flotant-3 {
    70% {
        opacity: 1;
    }
}
@keyframes pantalla-flotant-despl {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(0, -20%);
    }
}

// animació 3 steps
@keyframes anim-3-1 {
    33% {
        opacity: 0;
    }
}
@keyframes anim-3-2 {
    33% {
        opacity: 1;
    }
    66% {
        opacity: 0;
    }
}
@keyframes anim-3-3 {
    66% {
        opacity: 1;
    }
}

// animació home-fadein
@keyframes home-fadeIn {
    100% {
        opacity: 1;
    }
}
