.is-hidden {
  display: none !important;
}
.is-visible {
  display: block !important;
}
.rounded {
  border-radius: $global-rounded;
}
.hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
}

/*https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/*/
.vh {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}
