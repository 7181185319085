.legals {
    text-align: left;
    font-size: rem-calc(12);
}
.legals-gris {
    color: #4d4d4d;
    font-size: rem-calc(12);
    a {
        color: #e71481!important;
    }
}