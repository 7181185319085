@media print {
  a[href]:after {
    content: '';
  }

  .main-header {
    border-bottom: 0 !important;
  }

  .main-nav-container,
  .main-nav,
  .mobile-menu,
  .main-footer ul,
  .main-footer img, {
      display: none;
  }
}
