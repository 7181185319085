.cookies {
    background-color: $darker-gray;
    background-image: linear-gradient(to bottom, #000000, #2d313d);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @media #{$medium-up} {
        padding: rem-calc(30 0);
    }

    p {
        color: #f7cf60;
        font-size: rem-calc(12);

        @media #{$medium-up} {
            text-align: left;
            margin: 0;
        }
        @media #{$large-up} {
            text-align: left;
            margin-top: .5em;
        }
    }

    a {
        color: $white;
    }

    .button {
        margin: 0;
    }
}
