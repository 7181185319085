.js-close {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        height: 1px;
        background: $white;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &:hover::before,
    &:hover::after {
        background: $darker-gray;
    }
}
