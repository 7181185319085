@mixin border {
    content: '';
    height: 5px;
    background-color: $white;
    position: absolute;
    z-index: 1;
    transition: width 1s;
}

.workflow {
    list-style: none;
    margin-left: auto;

    @media only screen and (min-width: 23.75em) and (max-width: $small-breakpoint) {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    @media #{$medium-up} {
        position: relative;
        height: rem-calc(860);
        width: rem-calc(680);
        margin-left: auto;
        margin-right: auto;
    }

    @media #{$large-up} {
        height: rem-calc(860);
        width: rem-calc(940);
    }

    .info-box {
        @media #{$medium-up} {
            position: absolute;
            height: rem-calc(420);

            &--ct-creacio {
                width: rem-calc(213.33);
                left: 0;
                top: 0;

                &:after {
                    @include border;
                    width: rem-calc(99);
                    left: $ico-webs-ct-creacio-width;
                    top: $ico-webs-ct-creacio-height/2;
                    margin-top: -2.5px;
                }
            }
            &--ct-disseny {
                width: rem-calc(213.33);
                left: rem-calc(213.33) + rem-calc(20);
                top: 0;
                &:after {
                    @include border;
                    width: rem-calc(50);
                    left: rem-calc(202);
                    top: $ico-webs-ct-creacio-height/2;
                    margin-top: -2.5px;
                }
            }
            &--ct-integracio {
                width: rem-calc(213.33);
                left: (rem-calc(213.33)*2) + rem-calc(40);
                top: 0;
                &:after {
                    content: '';
                    display: block;
                    width: 73px;
                    height: 386px;
                    background: url('img/ct-border-1.gif') no-repeat;
                    position: absolute;
                    right: -17px;
                    top: 124px;
                }
            }

            &--ct-testeig {
                width: rem-calc(213.33);
                right: 0;
                top: rem-calc(440);
                &:after {
                    @include border;
                    width: rem-calc(162);
                    left: rem-calc(-106);
                    top: 67px;
                    margin-top: -2.5px;
                }
            }
            &--ct-formacio {
                width: rem-calc(213.33);
                right: rem-calc(213.33) + rem-calc(20);
                top: rem-calc(440);
                &:after {
                    @include border;
                    width: rem-calc(122);
                    left: rem-calc(-121);
                    top: 67px;
                    margin-top: -2.5px;
                }
            }
            &--ct-llancament {
                width: rem-calc(213.33);
                right: (rem-calc(213.33)*2) + rem-calc(40);
                top: rem-calc(440);
            }
        }

        @media #{$large-up} {
            height: rem-calc(420);

            &--ct-creacio {
                width: rem-calc(220);
                &:after {
                    width: rem-calc(134);
                }
            }
            &--ct-disseny {
                width: $ico-webs-ct-disseny-width;
                left: rem-calc(270);
                &:after {
                    width: rem-calc(186);
                }
            }
            &--ct-integracio {
                width: rem-calc(300);
                left: auto;
                right: 0;
                top: 0;
                &:after {
                    width: 121px;
                    background-image: url('img/ct-border-2.gif');
                    right: -17px;
                }
            }

            &--ct-testeig {
                width: rem-calc(300);
                &:after {
                    width: rem-calc(217);
                    left: rem-calc(-117);
                }
            }
            &--ct-formacio {
                width: rem-calc(255);
                left: rem-calc(270);
                &:after {
                    width: rem-calc(284);
                    left: rem-calc(-157);
                }
            }
            &--ct-llancament {
                width: rem-calc(175);
                right: auto;
                left: 0;
            }
        }
    }
}
