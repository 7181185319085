.info-box {

    margin-bottom: $paragraph-margin-bottom * 3;

    @media #{$medium-up} {
        > * {
            text-align: left;
        }
        margin-bottom: 0;
    }
    h2 {
        @include demibold;
        font-size: rem-calc(20);
        span:not([lang]) {
            display: block;
        }
    }
    h3 {
        @include demibold;
        font-size: rem-calc(20);
        color: #4a4a4a;
        span:not([lang]) {
            display: block;
        }
    }
    p {
        
    }
    &--lg {
        text-align: center;
        margin-bottom: rem-calc(110);
    }

    &--icon {
        position: relative;

        .floor--intranets-4 .columns:nth-child(-n+3) & {
            margin-bottom: rem-calc(140);
        }

        &:before {
            content: '';
            display: block;
            margin: 0 auto $icon-margin-bottom + px;

            @media #{$medium-up} {
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    // som antaviana
    &--client {
        @media #{$medium-up} {
            padding-top: $ico-somantaviana-rse-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-somantaviana-unics;
            margin-top: $ico-somantaviana-rse-height - $ico-somantaviana-unics-height;
        }
    }
    &--rse {
        @media #{$medium-up} {
            padding-top: $ico-somantaviana-rse-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-somantaviana-rse;
        }
    }
    &--impl {
        @media #{$medium-up} {
            padding-top: $ico-somantaviana-rse-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-somantaviana-implicacio;
            margin-top: $ico-somantaviana-rse-height - $ico-somantaviana-implicacio-height;
        }
    }
    &--equip {
        @media #{$medium-up} {
            padding-top: $ico-somantaviana-rse-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-somantaviana-equip;
            margin-top: $ico-somantaviana-rse-height - $ico-somantaviana-equip-height;
        }
    }
    &--somusuaris {
        @media #{$medium-up} {
            padding-top: $ico-somantaviana-rse-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-somantaviana-pensantusuaris;
            margin-top: $ico-somantaviana-rse-height - $ico-somantaviana-rse-height;
        }
    }
     &--somqualitat {
        @media #{$medium-up} {
            padding-top: $ico-somantaviana-rse-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-somantaviana-qualitat;
            margin-top: $ico-somantaviana-rse-height - $ico-somantaviana-rse-height;
        }
    }

    // webs
    &--usuaris {
        @media #{$medium-up} {
            padding-top: $ico-webs-usuaris-height + $icon-margin-bottom;
        }
        &:before {

            @extend .ico-webs-usuaris;
        }
    }
    &--responsive-2 {
        @media #{$medium-up} {
            padding-top: $ico-webs-usuaris-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-responsive;
            margin-top: $ico-webs-usuaris-height - $ico-webs-responsive-height;
        }
    }
    &--cercadors {
        @media #{$medium-up} {
            padding-top: $ico-webs-usuaris-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-cercadors;
            margin-top: $ico-webs-usuaris-height - $ico-webs-cercadors-height;
        }
    }
    &--velocitat {
        h2 {
            color: black;
        }
        @media #{$medium-up} {
            padding-left: $ico-webs-velocitat-width + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-velocitat;

        }
    }
    &--gestionen {
        @media #{$medium-up} {
            padding-top: $ico-webs-accessibles-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-gestionen;
            //@extend .ico-illustracio-llapis;
            margin-top: $ico-webs-accessibles-height - $ico-webs-gestionen-height;
        }
        h2, p {
            /*color: #4a4a4a;*/
            color: black;
        }
    }
    &--accessibles {
        @media #{$medium-up} {
            padding-top: $ico-webs-accessibles-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-accessibles;
        }
        h2, p {
            color: black;
        }
    }
    &--qualitat {
        @media #{$medium-up} {
            padding-top: $ico-webs-accessibles-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-qualitat;
            margin-top: $ico-webs-accessibles-height - $ico-webs-qualitat-height;
        }
        h2, p {
            color: black;
        }
    }
    &--estrategia {
        @media #{$medium-up} {
            padding-top: $ico-webs-estrategia-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-estrategia;
        }
         h2, p {
            color: black;
        }
    }
    &--metodologia {
        .h-a,
        .sm, h2, p {
            text-align: center;
        }
        @media #{$medium-up} {
            padding-top: $ico-webs-estrategia-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-metodologia;
            @media #{$medium-up} {
                left: 50%;
                margin-left: -$ico-webs-metodologia-width / 2;
                margin-top: $ico-webs-estrategia-height - $ico-webs-metodologia-height;
            }
        }
        h2, p {
            color: black;
        }
        .button {
            border-color: black;
            color: black;
            &:hover {
                background-color: black;
            }
        }
    }

    // com treballem
    &--ct-definicio {
        @media #{$medium-up} {
            * {
                text-align: center;
            }
            margin-bottom: $paragraph-margin-bottom * 2;
        }
    }
    &--ct-creacio {
        @media #{$medium-up} {
            padding-top: $ico-webs-ct-creacio-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-ct-creacio;
        }
    }
    &--ct-disseny {
        @media #{$medium-up} {
            padding-top: $ico-webs-ct-creacio-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-ct-disseny;
            margin-top: 12px;
        }
    }
    &--ct-integracio {
        @media #{$medium-up} {
            padding-top: $ico-webs-ct-creacio-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-ct-integracio;
            margin-top: 26px;
            @media #{$medium-up} {
                margin-left: 18px;
            }
        }
    }
    &--ct-testeig {
        @media #{$medium-up} {
            padding-top: $ico-webs-ct-formacio-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-ct-testeig;
            margin-top: ($ico-webs-ct-formacio-height - $ico-webs-ct-testeig-height)/2;
            left: 50%;
            @media #{$medium-up} {
                margin-left: -(($ico-webs-ct-testeig-width)/2);
            }
        }
    }
    &--ct-formacio {
        @media #{$medium-up} {
            padding-top: $ico-webs-ct-formacio-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-ct-formacio;
            @media #{$large-up} {
                right: 0;
                left: auto;
            }
        }
    }
    &--ct-llancament {
        * {
            color: $darker-gray;
        }
        @media #{$medium-up} {
            padding-top: $ico-webs-ct-formacio-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-webs-ct-llancament;
            margin-top: (($ico-webs-ct-formacio-height - $ico-webs-ct-llancament-height)/2) + 3;
        }
    }
    &--manteniment {
        text-align: center;
        .h-a,
        .sm, h2 {
            text-align: center;
        }
        @media #{$medium-up} {
            padding-top: $ico-manteniment-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-manteniment;
            left: 50%;
            @media #{$medium-up} {
                margin-left: -$ico-manteniment-width / 2;
            }
        }
    }

    // intranets
    &--disseny {
        @media #{$medium-up} {
            padding-top: $ico-intranets-disseny-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-intranets-disseny;
        }
    }
    &--definicio {
        @media #{$medium-up} {
            padding-top: $ico-intranets-disseny-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-intranets-definicio;
            margin-top: $ico-intranets-disseny-height - $ico-intranets-definicio-height;
        }
    }
    &--1 {
        @media #{$medium-only} {
            padding-top: $ico-intranets-usuari-height + $icon-margin-bottom;
            &:before {
                margin-top: $ico-intranets-usuari-height - $ico-intranets-responsive-height;
            }
        }
        @media #{$medium-up} {
            padding-top: $ico-intranets-social-height + $icon-margin-bottom;
        }
        &:before {
            margin-top: $ico-intranets-social-height - $ico-intranets-responsive-height;
            @extend .ico-intranets-responsive;
        }
    }
    &--2 {
        @media #{$medium-only} {
            padding-top: $ico-intranets-usuari-height + $icon-margin-bottom;
        }
        @media #{$medium-up} {
            padding-top: $ico-intranets-social-height + $icon-margin-bottom;
        }
        &:before {
            margin-top: $ico-intranets-social-height - $ico-intranets-usuari-height;
            @extend .ico-intranets-usuari;
        }
    }
    &--3 {
        @media #{$medium-only} {
            padding-top: $ico-intranets-social-height + $icon-margin-bottom;
        }
        @media #{$medium-up} {
            padding-top: $ico-intranets-social-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-intranets-social;
        }
    }
    &--4 {
        @media #{$medium-only} {
            padding-top: $ico-intranets-social-height + $icon-margin-bottom !important;
            &:before {
                margin-top: $ico-intranets-social-height - $ico-intranets-flexible-height;
            }
        }
        @media #{$medium-up} {
            padding-top: $ico-intranets-flexible-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-intranets-flexible;
        }
    }
    &--5 {
        @media #{$medium-up} {
            padding-top: $ico-intranets-flexible-height + $icon-margin-bottom;
        }
        &:before {
            margin-top: $ico-intranets-flexible-height - $ico-intranets-sistemes-height;
            @extend .ico-intranets-sistemes;
        }
    }
    &--6 {
        @media #{$medium-up} {
            padding-top: $ico-intranets-flexible-height + $icon-margin-bottom;
        }
        &:before {
            margin-top: $ico-intranets-flexible-height - $ico-intranets-gestor-height;
            @extend .ico-intranets-gestor;
        }
    }
    &--llicencies {
        @media #{$medium-up} {
            padding-top: $ico-intranets-llecencies-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-intranets-llecencies;
        }
    }
    &--garantia {
        @media #{$medium-up} {
            padding-top: $ico-intranets-llecencies-height + $icon-margin-bottom;
        }
        &:before {
            margin-top: $ico-intranets-llecencies-height - $ico-intranets-garantia-height;
            @extend .ico-intranets-garantia;
        }
    }

    // eines a mida
    &--eines-cadacas {
        @media #{$medium-up} {
            padding-top: $ico-eines-usuari-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-eines-cadacas;
            margin-top: $ico-eines-usuari-height - $ico-eines-cadacas-height;
        }
    }
    &--eines-ampliar {
        @media #{$medium-up} {
            padding-top: $ico-eines-usuari-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-eines-ampliar;
            margin-top: $ico-eines-usuari-height - $ico-eines-ampliar-height;
        }
    }
    &--eines-usuari {
        @media #{$medium-up} {
            padding-top: $ico-eines-usuari-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-eines-usuari;
        }
    }

    // cms
    &--gestor-generics {
        @media #{$medium-up} {
            padding-top: $ico-gestor-mida-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-gestor-generic;
            margin-top: $ico-gestor-mida-height - $ico-gestor-generic-height;
        }
    }
    &--gestor-mida {
        @media #{$medium-up} {
            padding-top: $ico-gestor-mida-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-gestor-mida;
        }
    }

    // comunicacio
    &--comunicacio-principal {
        @media #{$medium-up} {
            padding-left: $ico-comunicacio-principal-width + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-principal;
        }
    }
    &--comunicacio-estrategia {
        @media #{$medium-up} {
            padding-top: $ico-comunicacio-estrategia-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-estrategia;
        }
    }
    &--comunicacio-xxss {
        @media #{$medium-up} {
            padding-top: $ico-comunicacio-estrategia-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-xxss;
            margin-top: $ico-comunicacio-estrategia-height - $ico-comunicacio-xxss-height;
        }
    }
    &--comunicacio-contingut {
        @media #{$medium-up} {
            padding-top: $ico-comunicacio-estrategia-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-contingut;
            margin-top: $ico-comunicacio-estrategia-height - $ico-comunicacio-contingut-height;
        }
    }
    &--comunicacio-optimitza {
        @media #{$medium-up} {
            padding-top: $ico-comunicacio-optimitza-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-optimitza;
        }
    }
    &--comunicacio-butlletins {
        @media #{$medium-up} {
            padding-top: $ico-comunicacio-optimitza-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-butlletins;
            margin-top: $ico-comunicacio-optimitza-height - $ico-comunicacio-butlletins-height;
        }
    }
    &--comunicacio-anuncis {
        @media #{$medium-up} {
            padding-top: $ico-comunicacio-optimitza-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-anuncis;
            margin-top: $ico-comunicacio-optimitza-height - $ico-comunicacio-anuncis-height;
        }
    }
    &--comunicacio-marca {
        text-align: center;
        @media #{$medium-up} {
            padding-top: $ico-comunicacio-marca-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-comunicacio-marca;
            left: 50%;
            margin-left: -$ico-comunicacio-marca-width / 2;
        }
    }

    // tipografia
    &--tipopepel {
        @media #{$medium-up} {
            padding-top: $ico-tipos-assessorament-height + $icon-margin-bottom;
        }
        &:before {
            @extend .simbol-tipopepel;
            margin-top: $ico-tipos-assessorament-height - $simbol-tipopepel-height;
        }
    }
    &--tipos-exclusives {
        @media #{$medium-up} {
            padding-top: $ico-tipos-assessorament-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-tipos-exclusives;
            margin-top: $ico-tipos-assessorament-height - $ico-tipos-exclusives-height;
        }
    }
    &--assessorament {
        @media #{$medium-up} {
            padding-top: $ico-tipos-assessorament-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-tipos-assessorament;
        }
    }

    // infografies
    &--professional {
        @media #{$medium-up} {
            padding-top: $ico-infografia-professional-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-infografia-professional;
        }
    }
    &--animades {
        @media #{$medium-up} {
            padding-top: $ico-infografia-professional-height + $icon-margin-bottom;
        }
        &:before {
            @extend .ico-infografia-animades;
            margin-top: $ico-infografia-professional-height - $ico-infografia-animades-height;
        }
    }

    //salut
    &--health {
        margin: 0 auto;
        width: 70%;
        .ct {
            display: table;
            height: rem-calc(90);
            line-height: rem-calc(90);
                @media #{$medium-up} {
                    height: rem-calc(170);
                    line-height: rem-calc(170);
                } 
            .sm {
                @include book;
                text-align: center;
                font-size: rem-calc(18);
                line-height: rem-calc(30);
                height: rem-calc(90);
                vertical-align: middle;
                display: table-cell;
                width: 50%;
                border-bottom: 1px solid $white;
                padding-bottom: rem-calc(10);
                padding-top: rem-calc(10);
                @media #{$medium-up} {
                    font-size: rem-calc(25);
                    height: rem-calc(170);
                    border-bottom: 0;
                    padding-bottom: 0;
                    padding-top: 0;
                }   
            }
        }
        hr {
            max-width: rem-calc(332);
            border: 1px solid $white;
            display: none;
            @media #{$medium-up} {
                display: block;
            }
        }
    }
}
