.figure-container {
    margin: 0 0 $column-gutter;

    &--type-2 {
        margin-bottom: rem-calc(45);
    }

    &__descr {
        font-size: rem-calc(12);
        text-align: left;
        margin-bottom: rem-calc(10);

        &--type-2 {
            text-align: center;
            @media #{$medium-up} {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    // BST (figcaption a la dreta)
    &--bst {
        @media #{$medium-up} {
            @include clearfix;
            position: relative;

            img {
                float: left;
                width: 70%;
            }

            .figure-container__descr {
                position: absolute;
                bottom: $paragraph-margin-bottom*2;
                right: 0;
                width: 30%;
            }
        }
    }

    // Aj. Barcelona
    &--bcn {
        @media #{$medium-up} {
            text-align: center;
            img {
                display: inline;
            }
        }
    }
}
