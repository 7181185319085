@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 300;
  src: url(../fonts/Barlow/Barlow-italic-300.woff) format('woff'), url(../fonts/Barlow/Barlow-italic-300.ttf) format('truetype'), url(../fonts/Barlow/Barlow-italic-300.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Barlow/Barlow-italic-400.woff) format('woff'), url(../fonts/Barlow/Barlow-italic-400.ttf) format('truetype'), url(../fonts/Barlow/Barlow-italic-400.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: url(../fonts/Barlow/Barlow-normal-300.woff) format('woff'), url(../fonts/Barlow/Barlow-normal-300.ttf) format('truetype'), url(../fonts/Barlow/Barlow-normal-300.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Barlow/Barlow-normal-400);
  src: local('Barlow'), url(../fonts/Barlow/Barlow-normal-400.woff) format('woff'), url(../fonts/Barlow/Barlow-normal-400.ttf) format('truetype'), url(../fonts/Barlow/Barlow-normal-400.woff2) format('woff2'), url(../fonts/Barlow/Barlow-normal-400?#iefix) format('embedded-opentype'), url(../fonts/Barlow/Barlow-normal-400.svg#Barlow) format('svg');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/Barlow/Barlow-normal-600.woff) format('woff'), url(../fonts/Barlow/Barlow-normal-600.ttf) format('truetype'), url(../fonts/Barlow/Barlow-normal-600.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/Barlow/Barlow-normal-800.woff) format('woff'), url(../fonts/Barlow/Barlow-normal-800.ttf) format('truetype'), url(../fonts/Barlow/Barlow-normal-800.woff2) format('woff2');
}

